import { useContext } from 'react';
import { addRoof, removeRoof, selectRoof } from '../../roof';
import { actions, getDispatchSolarEnergyProject } from '../store';
import { SOLAR_MAP_EDIT_STATE } from '../../constants';
import { SolarMapVisualisationContext, SolarMapVisualisationDispatchContext } from '../context';
import { UseFieldArrayAppend, UseFieldArrayRemove } from 'react-hook-form';
import { SolarEnergyProject } from '@pages/NewLeads/project/solarEnergyProject/roofVisualisation/roofVisualisationTypes';
import { isSolarEnergyProject } from '@pages/NewLeads/project/solarEnergyProject/roofVisualisation/utils/useSolarMapVisualisation/utils';
import { getValuesFromAttributeRow } from '@pages/NewLeads/project/solarEnergyProject/utils/utils';
import { useGetSolarEnergyProjectSettings } from '@services/api/solarEnergyProjects/solarEnergyProjectsSettings';
import { useSelector } from 'react-redux';
import { selectAvailableMountingMaterialManufacturers } from '@redux/reducers/slices/solarEnergyProjectPage';
import { SolarEnergyProjectValues } from '@pages/NewLeads/project/solarEnergyProject/solarEnergyProjectZodSchema';

export const useHandleSelectRoof = () => {
  const { solarEnergyProject } = useContext(SolarMapVisualisationContext);
  const dispatch = useContext(SolarMapVisualisationDispatchContext);
  const dispatchSolarEnergyProject = getDispatchSolarEnergyProject(dispatch);
  return (roofId: string) => () => {
    const newSolarEnergyProject = selectRoof(solarEnergyProject, roofId);
    dispatchSolarEnergyProject(newSolarEnergyProject);
    dispatch(actions.setMapEditState(SOLAR_MAP_EDIT_STATE.ROOF));
  };
};

export const useHandleCreateRoof = () => {
  const { solarEnergyProject } = useContext(SolarMapVisualisationContext);
  const dispatch = useContext(SolarMapVisualisationDispatchContext);
  const dispatchSolarEnergyProject = getDispatchSolarEnergyProject(dispatch);
  const { solarPanelManufacturer, roofMaterial } = useGetSolarEnergyProjectSettings();
  const mountingMaterialManufacturerOptions = useSelector(selectAvailableMountingMaterialManufacturers);
  const initialRoofMaterial = getValuesFromAttributeRow(roofMaterial)[0];

  return (
      appendRoofCallback: UseFieldArrayAppend<SolarEnergyProjectValues>,
      roofName: string,
      defaultLathDimension: number | null,
    ): ((initialProject: SolarEnergyProject) => void | (() => void)) =>
    (initialProject?: SolarEnergyProject | undefined) => {
      const baseProjectObject = isSolarEnergyProject(initialProject) ? initialProject : solarEnergyProject;
      const newMapRoof = addRoof(baseProjectObject);
      dispatchSolarEnergyProject(newMapRoof);
      dispatch(actions.setMapEditState(SOLAR_MAP_EDIT_STATE.ROOF));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      //mountingMaterialManufacturerId is nullable, but I can't pass null to it
      appendRoofCallback({
        id: newMapRoof.roofs.at(-1)?.id ?? ``,
        name: roofName,
        solarPanelManufacturerId: getValuesFromAttributeRow(solarPanelManufacturer)?.[0].id as number,
        mountingMaterialManufacturerId: mountingMaterialManufacturerOptions.find((option) => !option.disabled),
        patches: [],
        ridgeHeight: 0,
        roofMaterialId: initialRoofMaterial?.id,
        tileHeight: initialRoofMaterial?.defaultTileHeight,
        tileWidth: initialRoofMaterial?.defaultTileWidth,
        solarPanelAttachmentId: initialRoofMaterial?.availableAttachmentIds?.[0],
        lathDimensionId: defaultLathDimension,
      });
      dispatch(actions.setMapEditState(SOLAR_MAP_EDIT_STATE.ROOF_SETTINGS));
    };
};

export const useHandleDeleteRoof = () => {
  const { solarEnergyProject } = useContext(SolarMapVisualisationContext);
  const dispatch = useContext(SolarMapVisualisationDispatchContext);
  const dispatchSolarEnergyProject = getDispatchSolarEnergyProject(dispatch);
  const handleCreateRoof = useHandleCreateRoof();

  return (
      removeRoofCallback: UseFieldArrayRemove,
      appendRoofCallback: UseFieldArrayAppend<SolarEnergyProjectValues>,
      indexToRemove: number,
      newRoofName: string,
      defaultLathDimension: number | null,
    ) =>
    () => {
      const roofToRemove = solarEnergyProject.roofs?.[indexToRemove];
      if (roofToRemove?.id) {
        removeRoofCallback(indexToRemove);
        const newSolarEnergyProject = removeRoof(solarEnergyProject, roofToRemove.id);
        if (newSolarEnergyProject.roofs.length > 0) {
          dispatchSolarEnergyProject(newSolarEnergyProject);
          dispatch(actions.setMapEditState(SOLAR_MAP_EDIT_STATE.ROOF));
        } else {
          handleCreateRoof(appendRoofCallback, newRoofName, defaultLathDimension)(newSolarEnergyProject);
        }
      }
    };
};
