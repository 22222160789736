import React, { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Select } from '@components/controls/react-hook-form-friendly/smart/MuiSelect';
import { RichTextInputWrapper } from '@components/controls/react-hook-form-friendly/smart/richTextInputWrapper';
import { ParsedBackendValidationResults } from '@components/controls/validations';
import { FormWrapper } from '@components/forms/form-wrapper';
import { GridLayoutWithMargins } from '@components/gridLayout/gridLayoutWithMargins';
import { SlideInViewFormWrapper } from '@components/slideInView/SlideInViewFormWrapper';
import { LeadAddressDto, LeadPropertyInfo, RoofDto, SolarEnergyProjectDto } from '@generatedTypes/data-contracts';
import { DevTool } from '@hookform/devtools';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { RoofVisualisationMap } from './roofVisualisation/RoofVisualisationMap';
import { addressDtoToAddressPosition, mToMm } from './roofVisualisation/utils/calculations';
import { CURRENT_PANELS_RESOLVER_VERSION } from './roofVisualisation/utils/panelsResolver/panelsResolver';
import { useHandleSolarEnergyDataChanges } from './utils/useHandleSolarEnergyDataChange/useHandleSolarEnergyDataChanges';
import { useGetInitialRoofsForForm } from './roofVisualisation/utils/useGetRoofsForForm';
import { DeleteHeader } from '@components/DeleteHeader/DeleteHeader';
import { ChipImageList } from '@components/controls/react-hook-form-friendly/smart/ChipImageList';
import { TerrainTypeHelperText } from './TerrainTypeHelperText';
import { Box, InputLabel, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { EditSubmitButtons } from '@components/editSubmitRow/editSubmitRow';
import { HorizontalLine } from '@components/dividers/horizontal-line';
import { useGetSolarEnergyProjectSettings } from '@services/api/solarEnergyProjects/solarEnergyProjectsSettings';
import { useGetSolarEnergyProjectTemplates } from '@services/api/solarEnergyProjects/solarEnergyProjectsTemplates';
import { getValuesFromAttributeRow } from '@pages/NewLeads/project/solarEnergyProject/utils/utils';
import { useErrorHandling } from './roofVisualisation/utils/useErrorHandling';
import { parseInputValueToSubmitNumberValue } from '@pages/NewLeads/utils';
import { mapLoadsOptions } from '@pages/NewLeads/project/solarEnergyProject/utils/mapLoadsOptions';
import { useGetLead } from '@services/api/leads/lead-info';
import { CUSTOM_SOLAR_PANEL_MANUFACTURER } from '@pages/NewLeads/project/solarEnergyProject/utils/constants';
import {
  SolarEnergyProjectValues,
  useSolarEnergyProjectSchema,
} from '@pages/NewLeads/project/solarEnergyProject/solarEnergyProjectZodSchema';

export type FormInputs = Omit<SolarEnergyProjectDto, `id` | `leadId` | `name`>;

export interface AddEditSolarEnergyProjectProps {
  onSubmit: (inputs: FormInputs) => void;
  onDelete: (projectId: number) => void;
  onClose: () => void;
  projectDetails: SolarEnergyProjectDto | null;
  leadPropertyDetails: LeadPropertyInfo | null;
  leadAddress: LeadAddressDto | null;
  beValidationResults?: ParsedBackendValidationResults | null;
  isDisabled?: boolean;
  isLoading?: boolean;
}

export const SolarEnergyProjectForm: React.FC<AddEditSolarEnergyProjectProps> = ({
  beValidationResults,
  isDisabled,
  isLoading,
  leadAddress,
  // leadPropertyDetails,
  onClose,
  onDelete,
  onSubmit,
  projectDetails,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);
  const {
    translate,
    translations: {
      common: {
        terrainTypes: { terrainType: terrainTypeTranslation },
      },
      leads: {
        details: {
          project: {
            addEditPage: { solarEnergy },
          },
          // propertyDetails,
        },
      },
    },
  } = useTranslations();

  useErrorHandling(beValidationResults);
  const { lead } = useGetLead();
  const { roofMaterial, terrainType, windLoad, snowLoad } = useGetSolarEnergyProjectSettings();
  const solarEnergyProjectTemplates = useGetSolarEnergyProjectTemplates();
  const terrainTypeValues = getValuesFromAttributeRow(terrainType);
  const { windLoadOptions, snowLoadOptions } = mapLoadsOptions({
    snowLoadAttributeValues: getValuesFromAttributeRow(snowLoad),
    windLoadAttributeValues: getValuesFromAttributeRow(windLoad),
  });

  const schema = useSolarEnergyProjectSchema();
  const defaultFormValues: SolarEnergyProjectValues = {
    windLoadId: windLoadOptions[0]?.value,
    snowLoadId: snowLoadOptions[0]?.value,
    projectProductTemplateId: solarEnergyProjectTemplates[0]?.value,
    comment: ``,
    panelsResolverVersion: CURRENT_PANELS_RESOLVER_VERSION,
    roofs: [],
  };

  const formValues = useGetInitialRoofsForForm({
    projectDetails,
    templateOptionsData: solarEnergyProjectTemplates,
    address: lead?.address,
    windLoadOptions,
    snowLoadOptions,
    terrainTypeValues,
  }) as SolarEnergyProjectValues;

  const formMethods = useForm<SolarEnergyProjectValues>({
    defaultValues: defaultFormValues,
    values: formValues,
    resolver: zodResolver(schema),
  });
  const { setError, handleSubmit, control, setValue, watch, getValues } = formMethods;
  useHandleSolarEnergyDataChanges(setValue, watch, getValues);

  useEffect(() => {
    if (beValidationResults) {
      Object.entries(beValidationResults.errors).forEach(([key, error]) => {
        setError(key as keyof SolarEnergyProjectValues, { message: error[0] });
      });
    }
  }, [beValidationResults, setError]);

  const submitHandler = useCallback(
    (values: SolarEnergyProjectValues) => {
      const forSubmit: FormInputs = {
        panelsResolverVersion: values.panelsResolverVersion,
        projectProductTemplateId: values.projectProductTemplateId,
        snowLoad: values.snowLoadId,
        windLoad: values.windLoadId,
        comment: values.comment,
        terrainTypeId: values.terrainTypeId,
        // TODO: Please fix id generation on roof, contract says it has to be a number custom type says it is a string
        roofs: values.roofs.map(({ ...roofData }) => {
          const {
            roofMaterialId,
            tileHeight,
            tileWidth,
            tinOrMetalThicknessId,
            solarPanelAttachmentId,
            distanceBetweenTopsTinOrMetal,
            distanceBetweenFolds,
            lathDimensionId,
            ridgeHeight,
            solarPanelManufacturerId,
            solarPanelSize,
            customSolarPanel,
            ...restRoofData
          } = roofData;

          const selectedRoofMaterial = getValuesFromAttributeRow(roofMaterial).find(({ id }) => id === roofMaterialId);

          const roofMaterialValues = {
            lathDimensionId: lathDimensionId,
            tileHeight: selectedRoofMaterial?.defaultTileHeight ? tileHeight : null,
            tileWidth: selectedRoofMaterial?.defaultTileWidth ? tileWidth : null,
            tinOrMetalThicknessId: selectedRoofMaterial?.availableTinOrMetalThicknessIds?.length
              ? tinOrMetalThicknessId
              : null,
            solarPanelAttachmentId: selectedRoofMaterial?.availableAttachmentIds?.length
              ? solarPanelAttachmentId
              : null,
            distanceBetweenTopsTinOrMetal: selectedRoofMaterial?.defaultDistanceBetweenTopsTinOrMetal
              ? parseInputValueToSubmitNumberValue(distanceBetweenTopsTinOrMetal)
              : null,
            distanceBetweenFolds: selectedRoofMaterial?.defaultDistanceBetweenFolds
              ? parseInputValueToSubmitNumberValue(distanceBetweenFolds)
              : null,
          };

          const solarPanelSizeData =
            solarPanelManufacturerId === CUSTOM_SOLAR_PANEL_MANUFACTURER
              ? { customSolarPanel, solarPanelManufacturerId }
              : {
                  solarPanelManufacturerId,
                  solarPanelWidthId: solarPanelSize?.split(`,`)[0],
                  solarPanelHeightId: solarPanelSize?.split(`,`)[1],
                };

          return {
            ...restRoofData,
            ...roofMaterialValues,
            ...solarPanelSizeData,
            roofMaterialId,
            ridgeHeight: mToMm(ridgeHeight ?? 0),
            patches: roofData.patches.map(({ shapeType, ...restPatchData }) => ({
              ...restPatchData,
              is90Degrees: shapeType === `straight`,
            })),
            id: isNaN(Number(roofData?.id)) ? undefined : Number(roofData?.id),
          };
        }) as unknown as RoofDto[],
      } as FormInputs;

      onSubmit(forSubmit);
    },
    [onSubmit, roofMaterial],
  );

  const selectedTerrainTypeId = useWatch({ control, name: `terrainTypeId` });

  const handleDelete = useMemo(() => {
    if (projectDetails?.id && !isDisabled) {
      return () => onDelete(projectDetails?.id ?? -1);
    }
    return null;
  }, [isDisabled, onDelete, projectDetails?.id]);

  const mapSize = {
    width: isMobile ? `100vw` : `50vw`,
    height: isMobile ? 400 : 600,
  };

  const position = useMemo(() => {
    return addressDtoToAddressPosition(leadAddress);
  }, [leadAddress]);

  if (isLoading) return <div>Loading</div>;

  return (
    <SlideInViewFormWrapper isDisabled={isDisabled}>
      <FormProvider {...formMethods}>
        <GridLayoutWithMargins paddingBlockStart={4}>
          <DeleteHeader title={translate(solarEnergy.header)} onDelete={handleDelete} />
        </GridLayoutWithMargins>

        <FormWrapper classes="no-gap">
          {/* This code might be needed in the future */}
          {/* more info here: https://dev.azure.com/RexelSE-Application/MyRexolution/_workitems/edit/4885 */}
          {/* <GridLayoutWithMargins>
            <div className="column gap-small">
              <h2 className="bold">{translate(propertyDetails.title)}</h2>
              <PropertyDetailsContent leadPropertyDetails={leadPropertyDetails} />
            </div>
          </GridLayoutWithMargins> */}

          {/* <GridLayoutWithMargins>
            <hr className="horizontal-line dark fw" />
          </GridLayoutWithMargins> */}

          <GridLayoutWithMargins>
            <Stack spacing={2}>
              <Typography variant="h2">{translate(solarEnergy.roofProjectHeader)}</Typography>
              {leadAddress ? (
                <Typography variant="h3">
                  {leadAddress?.street}, {leadAddress?.zipCode} {leadAddress?.city}
                </Typography>
              ) : null}
              <Stack direction="row" spacing={2}>
                {!!lead?.address?.windLoad && !lead?.address?.snowAndWindLoadManuallySet ? (
                  <Stack>
                    <InputLabel required>{translate(solarEnergy.windLoad)}</InputLabel>
                    <Typography>{lead?.address?.windLoad}</Typography>
                  </Stack>
                ) : (
                  <Select
                    control={control}
                    name="windLoadId"
                    label={translate(solarEnergy.windLoad)}
                    options={windLoadOptions}
                    disabled={!!lead?.address?.windLoad && !lead?.address?.snowAndWindLoadManuallySet}
                    sx={{ width: 180 }}
                    required
                  />
                )}
                {!!lead?.address?.snowLoad && !lead?.address?.snowAndWindLoadManuallySet ? (
                  <Stack>
                    <InputLabel required>{translate(solarEnergy.snowLoad)}</InputLabel>
                    <Typography>{lead?.address?.snowLoad}</Typography>
                  </Stack>
                ) : (
                  <Select
                    control={control}
                    name="snowLoadId"
                    label={translate(solarEnergy.snowLoad)}
                    options={snowLoadOptions}
                    disabled={!!lead?.address?.snowLoad && !lead?.address?.snowAndWindLoadManuallySet}
                    sx={{ width: 180 }}
                    required
                  />
                )}
              </Stack>
              <ChipImageList
                title={translate(terrainTypeTranslation)}
                control={control}
                chips={terrainTypeValues.map(({ name, imageUri, id, type }) => ({
                  title: name as string,
                  src: imageUri as string,
                  value: id,
                  subtitle: type,
                }))}
                name={`terrainTypeId`}
                defaultValue={terrainTypeValues[0]?.id ?? -1}
              />
              <TerrainTypeHelperText
                terrainTypes={terrainTypeValues}
                selectedTerrainType={String(selectedTerrainTypeId)}
              />
            </Stack>
          </GridLayoutWithMargins>

          <RoofVisualisationMap projectDetails={projectDetails} size={mapSize} position={position} />

          <GridLayoutWithMargins bgColor="bg-gray">
            <Stack gap={2}>
              <Typography variant="h2">{translate(solarEnergy.materialListHeader)}</Typography>
              <Typography variant="caption">{translate(solarEnergy.materialListNoteSelectTemplate)}</Typography>
              <Select
                control={control}
                name="projectProductTemplateId"
                label={translate(solarEnergy.materialTemplate)}
                options={solarEnergyProjectTemplates}
              />
            </Stack>

            <HorizontalLine />

            <Stack gap={2}>
              <Typography variant="h2">{translate(solarEnergy.commentsHeader)}</Typography>
              <RichTextInputWrapper
                label={translate(solarEnergy.comments)}
                placeholder={`${translate(solarEnergy.ownNotes)}...`}
                control={control}
                name="comment"
              />
            </Stack>

            <HorizontalLine />

            <Box display="flex" justifyContent="end">
              <EditSubmitButtons onSubmit={handleSubmit(submitHandler)} onClose={onClose} disabledSubmit={isDisabled} />
            </Box>
          </GridLayoutWithMargins>
        </FormWrapper>
      </FormProvider>
      <DevTool control={control} />
    </SlideInViewFormWrapper>
  );
};
