import TextFieldCore from '@mui/material/TextField';
import { NumericFormat } from 'react-number-format';
import { FieldValues, useController } from 'react-hook-form';
import { TextFieldProps } from '@components/controls/react-hook-form-friendly/smart/MuiInput';

const DEFAULT_DECIMAL_SCALE = 2;

type MuiNumberInputProps<T extends FieldValues> = TextFieldProps<T> & {
  decimalScale?: number;
  max?: number | null;
  min?: number | null;
};

export const MuiNumberInput = <T extends FieldValues>({
  control,
  name,
  helperText,
  decimalScale = DEFAULT_DECIMAL_SCALE,
  max,
  min,
  ...restProps
}: MuiNumberInputProps<T>) => {
  const { field, fieldState } = useController({ control, name });

  const onBlur = () => {
    if (min !== undefined && min !== null && Number(field.value) < min) {
      field.onChange(min);
    }
    if (max !== undefined && max !== null && Number(field.value) > max) {
      field.onChange(max);
    }
    field.onBlur();
  };

  return (
    <NumericFormat
      {...restProps}
      defaultValue={restProps.defaultValue as number | string | null}
      customInput={TextFieldCore}
      value={field.value}
      onChange={field.onChange}
      onBlur={onBlur}
      helperText={helperText || fieldState.error?.message}
      error={!!fieldState.error}
      decimalSeparator=","
      decimalScale={decimalScale}
      isAllowed={(values) => {
        const { floatValue } = values;
        if (floatValue === undefined) {
          return false;
        }
        return !(max !== undefined && max !== null && floatValue > max);
      }}
    />
  );
};
