import { useCallback, useContext, useEffect, useState } from 'react';
import {
  SolarEnergyProjectPatchProductionProps,
  useFetchSolarEnergyProjectPatchProduction,
} from '@services/api/solarEnergyProjects/solarEnergyProjectPatchProduction';
import { useSelectedRoofAndPatch } from '../roofVisualisation/utils/accessors';
import { isFetchSolarEnergyProjectPatchProductionProps, UseUpdateProductionProps } from './utils';
import { EnqueuePatchContext } from '@pages/NewLeads/project/solarEnergyProject/roofVisualisation/utils/useSavePatchQueue';
import { useFormContext, useWatch } from 'react-hook-form';
import { parseInputValueToSubmitNumberValue } from '@pages/NewLeads/utils';
import { useDispatch } from 'react-redux';
import { setIsProductionLoading } from '@redux/reducers/slices/solarEnergyProjectPage';
import { CUSTOM_SOLAR_PANEL_MANUFACTURER } from '@pages/NewLeads/project/solarEnergyProject/utils/constants';
import { SolarEnergyProjectValues } from '@pages/NewLeads/project/solarEnergyProject/solarEnergyProjectZodSchema';

const getSelectedPatchIndexOrDefault = (selectedPatchIndex: number) =>
  selectedPatchIndex >= 0 ? selectedPatchIndex : 0;

const getProductionProps = () => {
  const formFunctions = useFormContext<SolarEnergyProjectValues>();
  const { selectedRoof, selectedRoofIndex, selectedPatchIndex } = useSelectedRoofAndPatch();

  const roofsLength =
    useWatch({
      control: formFunctions.control,
      name: `roofs`,
    })?.length ?? 0;

  const solarPanelManufacturerIds = useWatch({
    control: formFunctions.control,
    name: Array.from(Array(roofsLength))?.map((_, index) => `roofs.${index}.solarPanelManufacturerId` as const),
  });
  const solarPanelSizes = useWatch({
    control: formFunctions.control,
    name: Array.from(Array(roofsLength))?.map((_, index) => `roofs.${index}.solarPanelSize` as const),
  });
  const solarPanelCustomWidths = useWatch({
    control: formFunctions.control,
    name: Array.from(Array(roofsLength))?.map((_, index) => `roofs.${index}.customSolarPanel.width` as const),
  });
  const solarPanelCustomHeights = useWatch({
    control: formFunctions.control,
    name: Array.from(Array(roofsLength))?.map((_, index) => `roofs.${index}.customSolarPanel.height` as const),
  });
  const solarPanelCustomPowers = useWatch({
    control: formFunctions.control,
    name: Array.from(Array(roofsLength))?.map((_, index) => `roofs.${index}.customSolarPanel.outputPower` as const),
  });
  const angles = useWatch({
    control: formFunctions.control,
    name: Array.from(Array(roofsLength))?.map((_, index) => `roofs.${index}.patches` as const),
  });

  const [
    solarPanelManufacturerId,
    solarPanelSize,
    solarPanelCustomWidth,
    solarPanelCustomHeight,
    solarPanelCustomPower,
    angle,
  ] = [
    solarPanelManufacturerIds?.[selectedRoofIndex],
    solarPanelSizes?.[selectedRoofIndex],
    solarPanelCustomWidths?.[selectedRoofIndex],
    solarPanelCustomHeights?.[selectedRoofIndex],
    solarPanelCustomPowers?.[selectedRoofIndex],
    angles?.[selectedRoofIndex]?.[getSelectedPatchIndexOrDefault(selectedPatchIndex)]?.angle,
  ];

  const numberOfSolarPanels =
    selectedRoof?.patches
      ?.find((_, index) => index === getSelectedPatchIndexOrDefault(selectedPatchIndex))
      ?.panels.filter(({ active, pointOnParent }) => active && pointOnParent).length ?? 0;

  return {
    solarPanelManufacturerId,
    solarPanelSize,
    solarPanelCustomWidth,
    solarPanelCustomHeight,
    solarPanelCustomPower,
    angle,
    numberOfSolarPanels,
  };
};

export const useUpdateProduction = ({ position }: UseUpdateProductionProps) => {
  const dispatch = useDispatch();
  const enqueuePatch = useContext(EnqueuePatchContext);
  const { selectedPatch, selectedPatchIndex, selectedRoof } = useSelectedRoofAndPatch();
  const [previousAbortController, setPreviousAbortController] = useState<AbortController | null>(null);

  const {
    solarPanelManufacturerId,
    solarPanelSize,
    solarPanelCustomWidth,
    solarPanelCustomHeight,
    solarPanelCustomPower,
    angle,
    numberOfSolarPanels,
  } = getProductionProps();

  const { post: fetchSolarEnergyProjectPatchProduction, isLoading } = useFetchSolarEnergyProjectPatchProduction({
    onSuccess: (production, props) => {
      if (
        (selectedPatch || selectedRoof?.patches?.[getSelectedPatchIndexOrDefault(selectedPatchIndex)]) &&
        props.numberOfSolarPanels === numberOfSolarPanels &&
        props.angle === Number(parseInputValueToSubmitNumberValue(angle)) &&
        (props.solarPanelManufacturerId === solarPanelManufacturerId || !props.solarPanelManufacturerId)
      ) {
        const patch = selectedPatch ?? selectedRoof?.patches?.[getSelectedPatchIndexOrDefault(selectedPatchIndex)];
        if (patch) {
          enqueuePatch({
            ...patch,
            solarEnergyProduction: production,
          });
        }
      }
    },
  });

  const updateProduction = useCallback(() => {
    const baseFetchProps = {
      angle: Number(parseInputValueToSubmitNumberValue(angle)),
      latitude: position.lat,
      longitude: position.lng,
      roofId: !isNaN(Number(selectedRoof?.id)) ? selectedRoof?.id : undefined,
      numberOfSolarPanels,
    } as SolarEnergyProjectPatchProductionProps;
    const [width, height] = solarPanelSize?.split(`,`).map((value) => Number(value)) ?? [null, null];
    const selectedManufacturerFetchProps =
      solarPanelManufacturerId !== CUSTOM_SOLAR_PANEL_MANUFACTURER && width && height
        ? {
            solarPanelManufacturerId,
            solarPanelWidthId: width,
            solarPanelHeightId: height,
          }
        : null;
    const customSolarPanelProps =
      !selectedManufacturerFetchProps && solarPanelCustomWidth && solarPanelCustomHeight && solarPanelCustomPower
        ? {
            customSolarPanelWidth: solarPanelCustomWidth,
            customSolarPanelHeight: solarPanelCustomHeight,
            customSolarPanelOutputPower: solarPanelCustomPower,
          }
        : null;

    const fetchProps = {
      ...baseFetchProps,
      ...(selectedManufacturerFetchProps ?? {}),
      ...(customSolarPanelProps ?? {}),
    } as SolarEnergyProjectPatchProductionProps;

    if (isFetchSolarEnergyProjectPatchProductionProps(fetchProps)) {
      previousAbortController?.abort();
      setPreviousAbortController(fetchSolarEnergyProjectPatchProduction(fetchProps));
    }

    //skip fetchSolarEnergyProjectPatchProduction in dependency array to avoid infinite loop
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    angle,
    selectedPatchIndex,
    selectedRoof?.id,
    numberOfSolarPanels,
    position.lat,
    position.lng,
    solarPanelManufacturerId,
    solarPanelSize,
    solarPanelCustomWidth,
    solarPanelCustomHeight,
    solarPanelCustomPower,
  ]);

  useEffect(() => {
    updateProduction();
  }, [updateProduction]);

  useEffect(() => {
    dispatch(setIsProductionLoading(isLoading));
  }, [dispatch, isLoading]);
};
