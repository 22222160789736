import flow from 'lodash.flow';
import { ProductAttributeRowDto, RoofMaterialAttributeValueDto } from '@generatedTypes/data-contracts';
import { DropdownOption } from '@hooks/useForm/useFormTypes';
import { OptionWithAvailability } from '@redux/reducers/slices/solarEnergyProjectPage';
import { Dispatch } from 'redux';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { SolarEnergyProjectPatchProductionProps } from '@services/api/solarEnergyProjects/solarEnergyProjectPatchProduction';
import { SolarMapPositionType } from '../roofVisualisation/utils/useSolarMapVisualisation/useSolarMapVisualisation';
import { CUSTOM_SOLAR_PANEL_MANUFACTURER } from '@pages/NewLeads/project/solarEnergyProject/utils/constants';
import { SolarEnergyProjectValues } from '@pages/NewLeads/project/solarEnergyProject/solarEnergyProjectZodSchema';

const filterAvailableOptions = (options: OptionWithAvailability[]) => options.filter(({ disabled }) => !disabled);
const mapOptionsToIds = (options: OptionWithAvailability[]) => options.map(({ value }) => value);
export const getValuesFromAttributeRow = <T extends { values?: ProductAttributeRowDto[`values`] }>(
  attributeRow?: T,
): T[`values`] => attributeRow?.values ?? [];
export const getIdsFromAvailableOptions = flow(filterAvailableOptions, mapOptionsToIds);
export const getProductAttributeValueRowDtoForIds = (availableOptions: DropdownOption[]) => (attributeIds: number[]) =>
  attributeIds.map((attributeId) => ({
    id: attributeId,
    name: availableOptions.find(({ value }) => attributeId === value)?.label,
  })) ?? [];

export const mapAttributeValuesToIds = <AttributeValues extends { id: number }>(
  attributeValues: AttributeValues[],
): number[] => attributeValues.map(({ id }) => id);

export const isAvailableWithinOptions = (availableOptions: number[], idToFind?: string | number | null) =>
  typeof idToFind !== `undefined` && idToFind !== null
    ? !!availableOptions.find((id) => String(id) === String(idToFind))
    : false;

export const getRoofMaterialAndOrientations = (
  roof: SolarEnergyProjectValues[`roofs`][number] | null,
  roofMaterialsValues: RoofMaterialAttributeValueDto[],
): {
  roofMaterialId: number | null;
  mountingMaterialId: number | null;
  attachmentId: number | null;
  panelOrientations: number[];
  railOrientations: number[];
} => {
  if (!roof)
    return {
      roofMaterialId: null,
      mountingMaterialId: null,
      attachmentId: null,
      panelOrientations: [],
      railOrientations: [],
    };
  const roofMaterialId = roof.roofMaterialId ?? null;
  const mountingMaterialId = roof.mountingMaterialManufacturerId ?? null;
  const attachmentId = roof.solarPanelAttachmentId ?? null;
  const attachmentAvailable = roofMaterialsValues
    .find(({ id }) => id === roofMaterialId)
    ?.availableAttachmentIds?.includes(Number(attachmentId));
  const orientations = roof?.patches?.reduce<{ panels: number[]; rails: number[] }>(
    (acc, patch) => {
      const panelOrientation = patch.solarPanelOrientationId;
      const railOrientation = patch.railOrientationId;
      if (panelOrientation && !acc.panels.includes(panelOrientation)) {
        acc.panels.push(panelOrientation);
      }
      if (railOrientation && !acc.rails.includes(railOrientation)) {
        acc.rails.push(railOrientation);
      }
      return acc;
    },
    { panels: [], rails: [] },
  ) ?? { panels: [], rails: [] };
  return {
    roofMaterialId,
    mountingMaterialId,
    attachmentId: attachmentAvailable ? attachmentId : null,
    panelOrientations: orientations.panels,
    railOrientations: orientations.rails,
  };
};

export const getRoofIdToFind = (selectedRoofId: number | null, name = ``) => {
  const [roofName, roofId] = name.split(`.`);
  const roofIdFromName = roofName === `roofs` && !isNaN(Number(roofId)) ? Number(roofId) : null;
  const roofIdToFind = selectedRoofId ?? (roofIdFromName as number);
  return roofIdToFind !== null && name?.includes(`roofs.${roofIdToFind}`) ? roofIdToFind : null;
};

export const dispatchOptionsMappedWithAvailability = (
  options: DropdownOption<number>[],
  availableOptions: number[],
  action: ActionCreatorWithPayload<OptionWithAvailability[]>,
  dispatch: Dispatch,
) => {
  dispatch(
    action(
      options
        .filter((option) => isAvailableWithinOptions(availableOptions, option.value))
        .map((option) => ({
          ...option,
          disabled: false,
        })),
    ),
  );
};

export type UseUpdateProductionProps = {
  position: SolarMapPositionType;
};

export function isFetchSolarEnergyProjectPatchProductionProps(
  props: SolarEnergyProjectPatchProductionProps,
): props is SolarEnergyProjectPatchProductionProps {
  const containBaseProps =
    props.angle !== undefined &&
    props.latitude !== undefined &&
    props.longitude !== undefined &&
    props.numberOfSolarPanels !== undefined &&
    props.angle !== null &&
    props.latitude !== null &&
    props.longitude !== null &&
    props.numberOfSolarPanels !== null &&
    props.numberOfSolarPanels > 0;
  const containSelectedManufacturerProps =
    !!props.solarPanelManufacturerId &&
    props.solarPanelManufacturerId !== CUSTOM_SOLAR_PANEL_MANUFACTURER &&
    !!props.solarPanelWidthId &&
    !!props.solarPanelHeightId;
  const containCustomSolarPanelProps =
    (props.solarPanelManufacturerId === CUSTOM_SOLAR_PANEL_MANUFACTURER || !props.solarPanelManufacturerId) &&
    !!props.customSolarPanelWidth &&
    !!props.customSolarPanelHeight &&
    !!props.customSolarPanelOutputPower;
  return containBaseProps && (containSelectedManufacturerProps || containCustomSolarPanelProps);
}
