import { z } from 'zod';
import { CURRENT_PANELS_RESOLVER_VERSION } from '@pages/NewLeads/project/solarEnergyProject/roofVisualisation/utils/panelsResolver/panelsResolver';
import { useSolarEnergyProjectRoofsSuperRefine } from '@pages/NewLeads/project/solarEnergyProject/solarEnergyProjectZodSchemaValidations';

const zodRoofPatchShapeVertex = z.object({
  latitude: z.number(),
  longitude: z.number(),
});

const roofs = z.object({
  id: z.string(),
  name: z.string(),
  solarPanelManufacturerId: z.coerce.number().nullable(),
  solarPanelSize: z.string().nullable().optional(),
  roofMaterialId: z.coerce.number().nullable(),
  roofTypeId: z.coerce.number().nullable(),
  mountingMaterialManufacturerId: z.coerce.number().nullable(),
  ridgeHeight: z.coerce.number().nullable(),
  snowProtection: z.boolean().nullable(),
  tinOrMetalThicknessId: z.coerce.number().nullable().optional(),
  solarPanelAttachmentId: z.coerce.number().nullable(),
  lathDimensionId: z.coerce.number().optional().nullable(),
  tileWidth: z.coerce.number().nullable(),
  tileHeight: z.coerce.number().nullable(),
  distanceBetweenTopsTinOrMetal: z.coerce.number().max(300).nullable().optional(),
  distanceBetweenFolds: z.coerce.number().nullable().optional(),
  customSolarPanel: z
    .object({
      width: z.coerce.number().nullable().optional(),
      height: z.coerce.number().nullable().optional(),
      weight: z.coerce.number().nullable().optional(),
      outputPower: z.coerce.number().nullable().optional(),
    })
    .nullable()
    .optional(),
  patches: z
    .object({
      id: z.string().nullable(),
      solarPanelOrientationId: z.coerce.number().nullable(),
      angle: z.coerce.number().nullable(),
      direction: z.coerce.number().nullable(),
      shape: z
        .object({
          v1: zodRoofPatchShapeVertex,
          v2: zodRoofPatchShapeVertex,
          v3: zodRoofPatchShapeVertex,
          v4: zodRoofPatchShapeVertex.optional().nullable(),
          topWidth: z.number().optional(),
          rightHeight: z.number().optional(),
          leftHeight: z.number().optional(),
          bottomWidth: z.number().optional().nullable(),
        })
        .nullable(),
      shapeType: z.enum([`free`, `straight`]),
      numberOfRailsId: z.coerce.number(),
      railOrientationId: z.coerce.number(),
      width: z.coerce.number(),
      height: z.coerce.number(),
      numberOfRows: z.coerce.number(),
      numberOfColumns: z.coerce.number(),
      solarPanelGrid: z
        .array(
          z.object({
            col: z.coerce.number(),
            row: z.coerce.number(),
            isActive: z.boolean(),
            isInShape: z.boolean(),
          }),
        )
        .nullable(),
    })
    .array(),
});

export type SolarEnergyProjectRoofsType = z.infer<typeof roofs>;

export const useSolarEnergyProjectSchema = () => {
  const roofsSuperRefine = useSolarEnergyProjectRoofsSuperRefine();

  const solarEnergyProjectZodObject = {
    projectProductTemplateId: z.number(),
    comment: z.string(),
    panelsResolverVersion: z.number().default(CURRENT_PANELS_RESOLVER_VERSION),
    terrainTypeId: z.coerce.number().nullable().optional(),
    snowLoadId: z.coerce.number().nullable().optional(),
    windLoadId: z.coerce.number().nullable().optional(),
    roofs: roofs.superRefine(roofsSuperRefine).array(),
  };
  return z.object(solarEnergyProjectZodObject);
};

export type SolarEnergyProjectValues = z.infer<ReturnType<typeof useSolarEnergyProjectSchema>>;
