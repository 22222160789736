import { SolarEnergyProjectRoofsType } from '@pages/NewLeads/project/solarEnergyProject/solarEnergyProjectZodSchema';
import { z } from 'zod';
import { CUSTOM_SOLAR_PANEL_MANUFACTURER } from '@pages/NewLeads/project/solarEnergyProject/utils/constants';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useGetSolarEnergyProjectSettings } from '@services/api/solarEnergyProjects/solarEnergyProjectsSettings';
import { RoofMaterialAttributeValueDto, SolarPanelManufacturerAttributeDto } from '@generatedTypes/data-contracts';

const validateCustomSolarPanel = (data: SolarEnergyProjectRoofsType, ctx: z.RefinementCtx, message: string) => {
  if (data.solarPanelManufacturerId === CUSTOM_SOLAR_PANEL_MANUFACTURER) {
    const fields: (keyof NonNullable<SolarEnergyProjectRoofsType[`customSolarPanel`]>)[] = [
      `width`,
      `height`,
      `weight`,
      `outputPower`,
    ];
    fields.forEach((field) => {
      if (!data.customSolarPanel?.[field]) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: message,
          path: [`customSolarPanel`, field],
        });
      }
    });
  }
};

const validateSolarPanelSize = (
  data: SolarEnergyProjectRoofsType,
  ctx: z.RefinementCtx,
  solarPanelManufacturer: SolarPanelManufacturerAttributeDto | undefined,
  message: string,
) => {
  if (data.solarPanelManufacturerId === CUSTOM_SOLAR_PANEL_MANUFACTURER) {
    return;
  }
  const availableSolarPanelSizes = solarPanelManufacturer?.values?.find(
    (manufacturer) => manufacturer.id === data.solarPanelManufacturerId,
  )?.availableSolarPanelSizes;

  if (!availableSolarPanelSizes) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: message,
      path: [`solarPanelManufacturerId`],
    });
  }

  const [selectedSolarPanelWidth, selectedSolarPanelHeight] = data?.solarPanelSize?.split(`,`) ?? [`-1`, `-1`];
  const isSolarPanelWidthValid = availableSolarPanelSizes?.find(
    (size) => size.widthId === Number(selectedSolarPanelWidth),
  );
  const isSolarPanelHeightValid = availableSolarPanelSizes?.find(
    (size) => size.heightId === Number(selectedSolarPanelHeight),
  );

  if (!isSolarPanelWidthValid || !isSolarPanelHeightValid) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: message,
      path: [`solarPanelSize`],
    });
  }
};

const validateAdditionalRoofInputs = (
  data: SolarEnergyProjectRoofsType,
  ctx: z.RefinementCtx,
  roofMaterials: RoofMaterialAttributeValueDto[],
  greaterThanMessage: (input: number) => string,
  lesserThanMessage: (input: number) => string,
) => {
  const selectedRoofMaterialSettings = roofMaterials.find((rm) => rm.id === data.roofMaterialId);
  if (!selectedRoofMaterialSettings) {
    return;
  }

  if (
    selectedRoofMaterialSettings.minDistanceBetweenFolds &&
    Number(data.distanceBetweenFolds) < selectedRoofMaterialSettings.minDistanceBetweenFolds
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: greaterThanMessage(selectedRoofMaterialSettings.minDistanceBetweenFolds),
      path: [`distanceBetweenFolds`],
    });
  }

  if (
    selectedRoofMaterialSettings.maxDistanceBetweenFolds &&
    Number(data.distanceBetweenFolds) > selectedRoofMaterialSettings.maxDistanceBetweenFolds
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: lesserThanMessage(selectedRoofMaterialSettings.maxDistanceBetweenFolds),
      path: [`distanceBetweenFolds`],
    });
  }

  if (
    selectedRoofMaterialSettings.maxTileHeight &&
    Number(data.tileHeight) > selectedRoofMaterialSettings.maxTileHeight
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: lesserThanMessage(selectedRoofMaterialSettings.maxTileHeight),
      path: [`tileHeight`],
    });
  }

  if (selectedRoofMaterialSettings.maxTileWidth && Number(data.tileWidth) > selectedRoofMaterialSettings.maxTileWidth) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: lesserThanMessage(selectedRoofMaterialSettings.maxTileWidth),
      path: [`tileWidth`],
    });
  }

  if (selectedRoofMaterialSettings.maxDistanceBetweenTopsTinOrMetal) {
    const maxDistance = selectedRoofMaterialSettings.maxDistanceBetweenTopsTinOrMetal.find(
      ({ solarPanelAttachmentId }) => Number(solarPanelAttachmentId) === Number(data.solarPanelAttachmentId),
    );
    if (maxDistance && Number(data.distanceBetweenTopsTinOrMetal) > maxDistance.value) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: lesserThanMessage(maxDistance.value),
        path: [`distanceBetweenTopsTinOrMetal`],
      });
    }
  }
};

export const useSolarEnergyProjectRoofsSuperRefine = () => {
  const {
    translateWithParams,
    translate,
    translations: {
      validationErrors: { LESS_THAN_OR_EQUAL, GREATER_THAN_OR_EQUAL, NOT_EMPTY },
    },
  } = useTranslations();

  const { roofMaterial, solarPanelManufacturer } = useGetSolarEnergyProjectSettings();
  const roofMaterials = roofMaterial?.values ?? [];

  return (data: SolarEnergyProjectRoofsType, ctx: z.RefinementCtx) => {
    validateCustomSolarPanel(data, ctx, translate(NOT_EMPTY));
    validateSolarPanelSize(data, ctx, solarPanelManufacturer, translate(NOT_EMPTY));
    validateAdditionalRoofInputs(
      data,
      ctx,
      roofMaterials,
      translateWithParams(GREATER_THAN_OR_EQUAL),
      translateWithParams(LESS_THAN_OR_EQUAL),
    );
  };
};
