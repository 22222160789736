import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import short from 'short-uuid';
import { RECTANGLE_EDGES_COUNT } from '../propertiesComponents/PatchStateProperties/EdgeFormComponents';
import { Edge, Patch, Roof, SolarEnergyProject, Vertex } from '../roofVisualisationTypes';
import {
  getRoofIdFromPatch,
  getSelectedRoofAndPatch,
  getSolarEnergyProjectWithDeselectedComponents,
} from './accessors';
import {
  areRotationsEqual,
  getCorrectRotationValue,
  getEdgesForForm,
  getVertices,
  mmToM,
  mToMm,
  setResolverPatchDataToForm,
} from './calculations';
import { defaultPanelsResolverResult, resolvePanels } from './panelsResolver/panelsResolver';
import { getCenter } from 'geolib';
import { geolibToLatLng } from './panelsResolver/v10/utils';
import { PANEL_SIZE } from '@pages/NewLeads/project/solarEnergyProject/roofVisualisation/utils/constants';
import { SolarEnergyProjectValues } from '@pages/NewLeads/project/solarEnergyProject/solarEnergyProjectZodSchema';

const getSelectedSegmentIndex = (edges: Edge[]): number => {
  const selectedSegment = edges.findIndex((edge) => edge.selected) ?? 0;
  return selectedSegment >= 0 ? selectedSegment : 0;
};

// Function returns distance multiplier for label based on the heading of the edge
// It is used to make sure that labels are not overlapping the edges
// Range of the factor is 1 - ~3.5 and is less for edges that are closer to horizontal
const getLabelDistanceMultiplier = (heading: number): number => {
  const quarterRange = heading > 90 ? 180 - heading : heading;
  return 1 + Math.sqrt(Math.abs(quarterRange)) / 4;
};

const countDistanceLabelPosition = (center: google.maps.LatLng, heading: number) =>
  google.maps.geometry.spherical.computeOffset(center, getLabelDistanceMultiplier(heading), heading);

const getCenterDataOfPoints = (start: google.maps.LatLng, end: google.maps.LatLng, polygon: google.maps.Polygon) => {
  const center = geolibToLatLng(getCenter([start.toJSON(), end.toJSON()])) || start;
  const heading = google.maps.geometry.spherical.computeHeading(start, end);
  const distancedCenter = google.maps.geometry.spherical.computeOffset(center, 1, heading + 90);
  const centerInPolygon = google.maps.geometry.poly.containsLocation(distancedCenter, polygon);
  return {
    center,
    heading,
    reversed: !centerInPolygon,
    rotation: centerInPolygon ? heading : heading + 180,
  };
};

const getEdgeMeasurementsData = (start: google.maps.LatLng, end: google.maps.LatLng, polygon: google.maps.Polygon) => {
  const distance = mToMm(google.maps.geometry.spherical.computeDistanceBetween(start, end));
  const { center, reversed, rotation } = getCenterDataOfPoints(start, end, polygon);
  const distanceLabelPosition = countDistanceLabelPosition(center, rotation - 90);

  return {
    distance,
    center,
    distanceLabelPosition,
    rotation,
    reversed,
  };
};

type GetEdgeData = {
  vertices: Vertex[];
  index: number;
  selectedSegment: number;
  polygon: google.maps.Polygon;
};
const getEdgeData = ({ vertices, selectedSegment, polygon, index }: GetEdgeData): Edge => {
  const nextVertexIndex = (index + 1) % vertices.length;
  const { latLng: start, id: startId } = vertices[index];
  const { latLng: end, id: endId } = vertices[nextVertexIndex];
  const edgeMeasurementsData = getEdgeMeasurementsData(start, end, polygon);
  return {
    id: index.toString(),
    start,
    startId,
    end,
    endId,
    selected: selectedSegment === index,
    ...edgeMeasurementsData,
  };
};

export const updateEdgesOnPatch = (patch: Patch): Patch => {
  const newEdges: Edge[] = [];

  if (patch.polygon && patch.vertices) {
    const { vertices, polygon, edges } = patch;
    const selectedSegment = getSelectedSegmentIndex(edges);
    if (vertices.length <= 1) {
      return { ...patch, edges: newEdges };
    }
    if (vertices.length === 2) {
      newEdges.push(getEdgeData({ vertices, index: 0, selectedSegment, polygon }));
    } else {
      vertices.forEach((_, index) => {
        newEdges.push(getEdgeData({ vertices, index, selectedSegment, polygon }));
      });
    }
  }
  return { ...patch, edges: newEdges };
};

export const selectPatch = (solarEnergyProject: SolarEnergyProject, patchId: string): SolarEnergyProject | null => {
  const roofId = getRoofIdFromPatch(patchId);
  const parentRoof = solarEnergyProject.roofs.find(({ id }) => id === roofId);
  if (parentRoof) {
    const newPatches = [...parentRoof.patches.map((patch) => ({ ...patch, selected: patch.id === patchId }))];
    const newSelectedRoof: Roof = { ...parentRoof, patches: newPatches };
    const cleanSolarEnergyProject = getSolarEnergyProjectWithDeselectedComponents(solarEnergyProject);
    const newRoofs = [
      ...cleanSolarEnergyProject.roofs.map((roof) =>
        roof.id === newSelectedRoof.id ? { ...newSelectedRoof, selected: true } : roof,
      ),
    ];
    return { ...cleanSolarEnergyProject, roofs: newRoofs };
  }
  return null;
};

const ROTATION_COMPARISON_PRECISION = 1;

const substractRotation = (rotation: number, rotationToSubstract: number): number =>
  Number(getCorrectRotationValue(rotation - rotationToSubstract).toFixed(ROTATION_COMPARISON_PRECISION));

export const shapeIsStraight = (patch: Patch): boolean =>
  patch.edges.reduce((acc, edge, edgeIndex) => {
    if (!acc) {
      return false;
    }
    const nextEdge = patch.edges[(edgeIndex + 1) % patch.edges.length];
    const [positiveRotation, negativeRotation] = [90, 90 + 180];
    const edgeRotation = getCorrectRotationValue(edge.rotation);
    const expectedNextEdgeRotations = [
      substractRotation(edgeRotation, positiveRotation),
      substractRotation(edgeRotation, negativeRotation),
    ];
    const currentNextEdgeRotation = Number(
      getCorrectRotationValue(nextEdge.rotation).toFixed(ROTATION_COMPARISON_PRECISION),
    );
    return expectedNextEdgeRotations.some((expectedRotation) =>
      areRotationsEqual(currentNextEdgeRotation, expectedRotation),
    );
  }, true);

export const getPatchWithStraightEdges = (patch: Patch): Patch => {
  const { vertices, edges } = patch;
  if (edges.length !== RECTANGLE_EDGES_COUNT) {
    return patch;
  }
  const baseWidthEdge = patch.edges[0];
  const { distance: heightDistance } = patch.edges[1];
  const heightRotation = baseWidthEdge.rotation + 90;
  const newVertices = [
    vertices[0],
    vertices[1],
    {
      ...vertices[2],
      latLng: google.maps.geometry.spherical.computeOffset(vertices[1].latLng, mmToM(heightDistance), heightRotation),
    },
    {
      ...vertices[3],
      latLng: google.maps.geometry.spherical.computeOffset(vertices[0].latLng, mmToM(heightDistance), heightRotation),
    },
  ];
  return { ...patch, vertices: newVertices };
};

const fixInvertedVertices = (vertices: Vertex[], polygon?: google.maps.Polygon | null): Vertex[] => {
  const isShapeRectangle = vertices.length === RECTANGLE_EDGES_COUNT;
  const verticesMatchPolygon = polygon?.getPath().getLength() ?? 0 === vertices.length;
  if (!(polygon && isShapeRectangle && verticesMatchPolygon)) {
    return vertices;
  }
  const { reversed } = getCenterDataOfPoints(vertices[0].latLng, vertices[1].latLng, polygon);
  return reversed ? [vertices[1], vertices[0], vertices[3], vertices[2]] : vertices;
};

export const updatePatch = (
  solarEnergyProject: SolarEnergyProject,
  map: google.maps.Map,
  patch: Patch,
): SolarEnergyProject => {
  const panelOrientation = patch.panelOrientation;
  const roofId = getRoofIdFromPatch(patch.id);
  const fixedPatch = { ...patch, vertices: fixInvertedVertices(patch.vertices, patch.polygon) };
  const newPatch = updateEdgesOnPatch(fixedPatch);
  const roof = solarEnergyProject.roofs.find(({ id }) => id === roofId);
  const panelSize = {
    width: roof?.panelWidth ?? PANEL_SIZE.width,
    height: roof?.panelHeight ?? PANEL_SIZE.height,
    marginBetween: PANEL_SIZE.marginBetween,
  };
  const panelsResolverResults =
    map && panelOrientation
      ? resolvePanels({
          map,
          patch: newPatch,
          resolverFunctionVersion: solarEnergyProject.panelsResolverVersion,
          panelSize,
        })
      : { ...defaultPanelsResolverResult, panels: patch.panels };
  const newRoofs = solarEnergyProject.roofs.map((roof) =>
    roof.id === roofId
      ? {
          ...roof,
          patches: [
            ...roof.patches.map((p) => (p.id === patch.id ? { ...p, ...newPatch, ...panelsResolverResults } : p)),
          ],
        }
      : roof,
  );
  return { ...solarEnergyProject, roofs: newRoofs };
};

export const addPatch = (solarEnergyProject: SolarEnergyProject): SolarEnergyProject | null => {
  const { selectedRoof } = getSelectedRoofAndPatch(solarEnergyProject.roofs);
  if (selectedRoof) {
    const newPatch: Patch = {
      edges: [],
      selected: true,
      id: `${selectedRoof.id}-p${short.generate()}`,
      vertices: [],
      panels: [],
      solarEnergyProduction: {
        yearlyProduction: 0,
        peakPower: 0,
        yearlyProductionInkWh: 0,
      },
      panelOrientation: null,
      width: 0,
      height: 0,
      columns: 0,
      rows: 0,
    };
    const newPatches = [...selectedRoof.patches.map((patch) => ({ ...patch, selected: false })), newPatch];
    const newSelectedRoof: Roof = { ...selectedRoof, patches: newPatches };
    const cleanSolarEnergyProject = getSolarEnergyProjectWithDeselectedComponents(solarEnergyProject);
    const newRoofs = [
      ...cleanSolarEnergyProject.roofs.map((roof) => (roof.id === newSelectedRoof.id ? newSelectedRoof : roof)),
    ];
    return { ...cleanSolarEnergyProject, roofs: newRoofs };
  }
  return null;
};

export const removePatch = (solarEnergyProject: SolarEnergyProject, patchId: string): SolarEnergyProject => {
  const roofId = getRoofIdFromPatch(patchId);
  const { selectedRoof } = getSelectedRoofAndPatch(solarEnergyProject.roofs);
  if (selectedRoof?.id === roofId) {
    const newPatches = [...selectedRoof.patches.filter(({ id }) => id !== patchId)];
    const newSelectedRoof: Roof = { ...selectedRoof, patches: newPatches };
    const newRoofs = [
      ...solarEnergyProject.roofs.map((roof) => (roof.id === newSelectedRoof.id ? newSelectedRoof : roof)),
    ];
    return { ...solarEnergyProject, roofs: newRoofs };
  }
  return solarEnergyProject;
};

export const isPatchShapeValid = (shape: Patch): boolean => {
  return (shape.polygon && shape.vertices && shape.vertices.length > 2) || false;
};

export const updatePatchFormValues =
  (setValue: UseFormSetValue<SolarEnergyProjectValues>) => (solarEnergyProject: SolarEnergyProject) => {
    const correctionOfDefaultRotationFromMap = 90;
    const { selectedPatch, selectedRoofIndex, selectedPatchIndex } = getSelectedRoofAndPatch(solarEnergyProject.roofs);
    if (selectedPatch) {
      const selectedEdge = selectedPatch.edges.find((edge) => edge.selected);
      if (selectedEdge) {
        const direction = getCorrectRotationValue(selectedEdge.rotation + correctionOfDefaultRotationFromMap);
        // TODO: backend can accepnt only int, when it will be able to accept floats than we have to change back to Number(direction.toFixed(2))
        setValue(`roofs.${selectedRoofIndex}.patches.${selectedPatchIndex}.direction`, Math.round(Number(direction)));
      }
      if (selectedPatch.vertices.length) {
        const vertices = getVertices(selectedPatch.vertices);
        const edges = getEdgesForForm(selectedPatch.edges);
        const shape: SolarEnergyProjectValues[`roofs`][number][`patches`][number][`shape`] = {
          ...vertices,
          ...edges,
        };
        setValue(`roofs.${selectedRoofIndex}.patches.${selectedPatchIndex}.shape`, shape);
      }
      if (selectedPatch.panels.length) {
        setResolverPatchDataToForm({
          panelsResolverResults: selectedPatch,
          setValue,
          roofIndex: selectedRoofIndex,
          patchIndex: selectedPatchIndex,
        });
      }
    }
  };
