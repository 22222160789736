import { createContext, useCallback, useEffect, useState } from 'react';
import { Patch, SolarEnergyProject } from '../roofVisualisationTypes';
import { getPatchWithStraightEdges, shapeIsStraight, updatePatch } from './patch';
import { UseFormGetValues } from 'react-hook-form/dist/types/form';

import { SolarEnergyProjectValues } from '@pages/NewLeads/project/solarEnergyProject/solarEnergyProjectZodSchema';

export const useGetShapeTypeForPatch =
  (solarEnergyProject: SolarEnergyProject, getValues?: UseFormGetValues<SolarEnergyProjectValues>) =>
  (patch: Patch): `free` | `straight` => {
    const { roofIndex, patchIndex } = solarEnergyProject.roofs.reduce(
      (acc, roof, roofIndex) => {
        const patchIndex = roof.patches.findIndex(({ id }) => id === patch.id);
        if (patchIndex >= 0) {
          acc = { roofIndex, patchIndex };
        }
        return acc;
      },
      { roofIndex: -1, patchIndex: -1 },
    );
    return getValues?.(`roofs.${roofIndex}.patches.${patchIndex}.shapeType`) ?? `free`;
  };

type UseSavePatchQueueProps = {
  solarEnergyProject: SolarEnergyProject;
  setSolarEnergyProject: (solarEnergyProject: SolarEnergyProject) => void;
  updatePatchFormValues?: (solarEnergyProject: SolarEnergyProject) => void;
  map: google.maps.Map | null;
  getValues?: UseFormGetValues<SolarEnergyProjectValues>;
};
export const useSavePatchQueue = ({
  map,
  solarEnergyProject,
  updatePatchFormValues,
  setSolarEnergyProject,
  getValues,
}: UseSavePatchQueueProps) => {
  const [patchEditingQueue, setPatchEditingQueue] = useState<Patch[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const getShapeTypeForPatch = useGetShapeTypeForPatch(solarEnergyProject, getValues);
  const saveNextPatch = useCallback(() => {
    if (patchEditingQueue.length === 0 || isSaving || !solarEnergyProject || !map) {
      return;
    }
    setIsSaving(true);
    let patchToUpdate = patchEditingQueue[0];
    if (getShapeTypeForPatch(patchToUpdate) === `straight` && !shapeIsStraight(patchToUpdate)) {
      patchToUpdate = getPatchWithStraightEdges(patchToUpdate);
    }
    const newSolarEnergyProject = updatePatch(solarEnergyProject, map, patchToUpdate);
    updatePatchFormValues?.(newSolarEnergyProject);
    setSolarEnergyProject(newSolarEnergyProject);
    setPatchEditingQueue((prevQueue) => prevQueue.slice(1));
    setIsSaving(false);
  }, [
    patchEditingQueue,
    isSaving,
    solarEnergyProject,
    map,
    getShapeTypeForPatch,
    updatePatchFormValues,
    setSolarEnergyProject,
  ]);

  useEffect(() => {
    saveNextPatch();
  }, [saveNextPatch]);

  return useCallback((patch: Patch) => {
    setPatchEditingQueue((prevQueue) => [...prevQueue, patch]);
  }, []);
};

function emptyInitial() {
  return;
}

export const EnqueuePatchContext = createContext<(patch: Patch) => void>(emptyInitial);
