import { authFetch } from '@services/api/utils';
import {
  CreateProductPackageRequest,
  CreateProductRequest,
  ManageableProductDto,
  ManageableProductPackageDto,
  ManageableProductRowDto,
  UpdateProductPackageRequest,
  UpdateProductRequest,
} from '@generatedTypes/data-contracts';
import { useCustomQuery } from '@hooks/useCustomQuery';
import {
  MANAGEABLE_PRODUCT,
  MANAGEABLE_PRODUCT_PACKAGE,
  PRODUCT_MANAGEMENT_PRODUCTS,
  SOLAR_ENERGY_PROJECT_SETTINGS,
} from '@variables/queryKeys';
import { useSelector } from 'react-redux';
import {
  selectSelectedManageableProductId,
  selectSelectedManageableProductPackageId,
} from '@redux/reducers/slices/productManagement';
import { useQueryClient } from 'react-query';
import { useCustomMutation } from '@hooks/useMutationWithBackendErrors';

export const fetchManageableProducts = async () => {
  const url = `${process.env.PROTECTED_API_URL}/product-management/products`;
  const response = await authFetch(url, {
    method: `GET`,
    mode: `cors`,
  });
  const json = await response.json();
  if (response.ok) {
    return json as ManageableProductRowDto[];
  }
  throw new Error(json.message);
};

export const fetchSelectedManageableProduct = async (productId: number | undefined) => {
  const url = `${process.env.PROTECTED_API_URL}/product-management/products/${productId}`;
  const response = await authFetch(url, {
    method: `GET`,
    mode: `cors`,
  });
  const json = await response.json();
  if (response.ok) {
    return json as ManageableProductDto;
  }
  throw new Error(json.message);
};

export const fetchSelectedManageableProductPackage = async (productPackageId: number | undefined) => {
  const url = `${process.env.PROTECTED_API_URL}/product-management/product-packages/${productPackageId}`;
  const response = await authFetch(url, {
    method: `GET`,
    mode: `cors`,
  });
  const json = await response.json();
  if (response.ok) {
    return json as ManageableProductPackageDto;
  }
  throw new Error(json.message);
};

export const createManageableProduct = async (product: CreateProductRequest) => {
  const url = `${process.env.PROTECTED_API_URL}/product-management/products`;
  const response = await authFetch(url, {
    method: `POST`,
    mode: `cors`,
    body: JSON.stringify(product),
  });
  const json = await response.json();
  if (response.ok) {
    return json as ManageableProductDto;
  }
  throw new Error(json.message);
};

export const updateManageableProduct = async (productId: number | undefined, product: UpdateProductRequest) => {
  const url = `${process.env.PROTECTED_API_URL}/product-management/products/${productId}`;
  if (!productId) {
    throw new Error(`Product id is required`);
  }
  const response = await authFetch(url, {
    method: `PUT`,
    mode: `cors`,
    body: JSON.stringify(product),
  });
  if (!response.ok) {
    const json = await response.json();
    throw new Error(json.message);
  }
};

export const deleteManageableProduct = async (productId?: number) => {
  if (!productId) {
    throw new Error(`Product id is required`);
  }
  const url = `${process.env.PROTECTED_API_URL}/product-management/products/${productId}`;
  const response = await authFetch(url, {
    method: `DELETE`,
    mode: `cors`,
  });
  if (response.ok) {
    return;
  }
  const json = await response.json();
  if (response.status === 409) {
    return json;
  }
  throw new Error(json.message);
};

export const createManageableProductPackage = async (productPackage: CreateProductPackageRequest) => {
  const url = `${process.env.PROTECTED_API_URL}/product-management/product-packages`;
  const response = await authFetch(url, {
    method: `POST`,
    mode: `cors`,
    body: JSON.stringify(productPackage),
  });
  const json = await response.json();
  if (response.ok) {
    return json as ManageableProductPackageDto;
  }
  throw new Error(json.message);
};

export const updateManageableProductPackage = async (
  productPackageId: number | undefined,
  productPackage: UpdateProductPackageRequest,
) => {
  if (!productPackageId) {
    throw new Error(`Product package id is required`);
  }
  const url = `${process.env.PROTECTED_API_URL}/product-management/product-packages/${productPackageId}`;
  const response = await authFetch(url, {
    method: `PUT`,
    mode: `cors`,
    body: JSON.stringify(productPackage),
  });
  if (!response.ok) {
    const json = await response.json();
    throw new Error(json.message);
  }
};

export const deleteManageableProductPackage = async (productPackageId?: number) => {
  if (!productPackageId) {
    throw new Error(`Product package id is required`);
  }
  const url = `${process.env.PROTECTED_API_URL}/product-management/product-packages/${productPackageId}`;
  const response = await authFetch(url, {
    method: `DELETE`,
    mode: `cors`,
  });
  if (response.ok) {
    return;
  }
  const json = await response.json();
  throw new Error(json.message);
};

export const useGetManageableProducts = () => {
  const { data, isLoading } = useCustomQuery({
    queryKey: PRODUCT_MANAGEMENT_PRODUCTS,
    queryFn: fetchManageableProducts,
  });

  return {
    manageableProducts: data || [],
    isLoadingManageableProducts: isLoading,
  };
};

export const useGetSelectedManageableProduct = () => {
  const selectedManageableProductId = useSelector(selectSelectedManageableProductId);
  const { data, isLoading } = useCustomQuery({
    queryKey: [MANAGEABLE_PRODUCT, selectedManageableProductId],
    queryFn: () => fetchSelectedManageableProduct(selectedManageableProductId),
    enabled: !!selectedManageableProductId,
  });

  return {
    selectedManageableProduct: data,
    isLoadingSelectedManageableProduct: isLoading,
  };
};

export const useCreateManageableProduct = (options?: { onSuccess?: () => void }) => {
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isLoading, validationErrors } = useCustomMutation({
    mutationFn: createManageableProduct,
    onSuccess: () => {
      queryClient.invalidateQueries(PRODUCT_MANAGEMENT_PRODUCTS);
      queryClient.invalidateQueries(SOLAR_ENERGY_PROJECT_SETTINGS);
      options?.onSuccess?.();
    },
  });

  return {
    createManageableProduct: mutate,
    createManageableProductAsync: mutateAsync,
    isCreatingManageableProduct: isLoading,
    createManageableProductValidationErrors: validationErrors,
  };
};

export const useUpdateManageableProduct = (options?: { onSuccess?: () => void }) => {
  const queryClient = useQueryClient();
  const selectedManageableProductId = useSelector(selectSelectedManageableProductId);
  const { mutate, mutateAsync, isLoading, validationErrors } = useCustomMutation({
    mutationFn: (product: UpdateProductRequest) => updateManageableProduct(selectedManageableProductId, product),
    onSuccess: () => {
      queryClient.invalidateQueries([PRODUCT_MANAGEMENT_PRODUCTS]);
      queryClient.invalidateQueries(SOLAR_ENERGY_PROJECT_SETTINGS);
      queryClient.invalidateQueries([MANAGEABLE_PRODUCT, selectedManageableProductId]);
      options?.onSuccess?.();
    },
  });

  return {
    updateManageableProduct: mutate,
    updateManageableProductAsync: mutateAsync,
    isUpdatingManageableProduct: isLoading,
    updateManageableProductValidationErrors: validationErrors,
  };
};

export type ConflictResponseRow = {
  category: string;
  packageId: number;
  packageName: string;
};

export const useDeleteManageableProduct = (options?: {
  onSuccess?: () => void;
  onConflict?: (errors: ConflictResponseRow[]) => void;
}) => {
  const queryClient = useQueryClient();
  const selectedManageableProductId = useSelector(selectSelectedManageableProductId);
  const { mutate, mutateAsync, isLoading } = useCustomMutation({
    mutationFn: () => deleteManageableProduct(selectedManageableProductId),
    onSuccess: (data) => {
      if (data?.errors?.length) {
        options?.onConflict?.(data.errors);
      } else {
        queryClient.invalidateQueries(PRODUCT_MANAGEMENT_PRODUCTS);
        queryClient.invalidateQueries(SOLAR_ENERGY_PROJECT_SETTINGS);
        options?.onSuccess?.();
      }
    },
  });

  return {
    deleteManageableProduct: mutate,
    deleteManageableProductAsync: mutateAsync,
    isDeletingManageableProduct: isLoading,
  };
};

export const useGetSelectedManageableProductPackage = () => {
  const selectedManageableProductPackageId = useSelector(selectSelectedManageableProductPackageId);
  const { data, isLoading } = useCustomQuery({
    queryKey: [MANAGEABLE_PRODUCT_PACKAGE, selectedManageableProductPackageId],
    queryFn: () => fetchSelectedManageableProductPackage(selectedManageableProductPackageId),
    enabled: !!selectedManageableProductPackageId,
  });

  return {
    selectedManageableProductPackage: data,
    isLoadingSelectedManageableProductPackage: isLoading,
  };
};

export const useCreateManageableProductPackage = (options?: { onSuccess?: () => void }) => {
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isLoading, validationErrors } = useCustomMutation({
    mutationFn: createManageableProductPackage,
    onSuccess: () => {
      queryClient.invalidateQueries(PRODUCT_MANAGEMENT_PRODUCTS);
      queryClient.invalidateQueries(SOLAR_ENERGY_PROJECT_SETTINGS);
      options?.onSuccess?.();
    },
  });

  return {
    createManageableProductPackage: mutate,
    createManageableProductPackageAsync: mutateAsync,
    isCreatingManageableProductPackage: isLoading,
    createManageableProductPackageValidationErrors: validationErrors,
  };
};

export const useUpdateManageableProductPackage = (options?: { onSuccess?: () => void }) => {
  const queryClient = useQueryClient();
  const selectedManageableProductPackageId = useSelector(selectSelectedManageableProductPackageId);
  const { mutate, mutateAsync, isLoading, validationErrors } = useCustomMutation({
    mutationFn: (productPackage: UpdateProductPackageRequest) =>
      updateManageableProductPackage(selectedManageableProductPackageId, productPackage),
    onSuccess: () => {
      queryClient.invalidateQueries([PRODUCT_MANAGEMENT_PRODUCTS]);
      queryClient.invalidateQueries(SOLAR_ENERGY_PROJECT_SETTINGS);
      queryClient.invalidateQueries([MANAGEABLE_PRODUCT_PACKAGE, selectedManageableProductPackageId]);
      options?.onSuccess?.();
    },
  });

  return {
    updateManageableProductPackage: mutate,
    updateManageableProductPackageAsync: mutateAsync,
    isUpdatingManageableProductPackage: isLoading,
    updateManageableProductPackageValidationErrors: validationErrors,
  };
};

export const useDeleteManageableProductPackage = (options?: { onSuccess?: () => void }) => {
  const queryClient = useQueryClient();
  const selectedManageableProductPackageId = useSelector(selectSelectedManageableProductPackageId);
  const { mutate, mutateAsync, isLoading } = useCustomMutation({
    mutationFn: () => deleteManageableProductPackage(selectedManageableProductPackageId),
    onSuccess: () => {
      queryClient.invalidateQueries(PRODUCT_MANAGEMENT_PRODUCTS);
      queryClient.invalidateQueries(SOLAR_ENERGY_PROJECT_SETTINGS);
      options?.onSuccess?.();
    },
  });

  return {
    deleteManageableProductPackage: mutate,
    deleteManageableProductPackageAsync: mutateAsync,
    isDeletingManageableProductPackage: isLoading,
  };
};
