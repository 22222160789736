import { Panel } from '@pages/NewLeads/project/solarEnergyProject/roofVisualisation/roofVisualisationTypes';
import { useSelectedRoofAndPatch } from '@pages/NewLeads/project/solarEnergyProject/roofVisualisation/utils/accessors';
import { useFormContext } from 'react-hook-form';

import { SolarEnergyProjectValues } from '@pages/NewLeads/project/solarEnergyProject/solarEnergyProjectZodSchema';

export const getNumberOfSelectedPanels = (panels: Panel[]) =>
  String(panels.filter(({ active }) => active).length || `-`);

export const useSelectedPatchAngleValue = () => {
  const { selectedRoofIndex, selectedPatchIndex } = useSelectedRoofAndPatch();
  const { watch } = useFormContext<SolarEnergyProjectValues>();
  const angle = watch(`roofs.${selectedRoofIndex}.patches.${selectedPatchIndex}.angle`)?.toString() ?? `0`;

  return `${angle}°`;
};
