import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import TextFieldCore from '@mui/material/TextField';
import { NumericFormat } from 'react-number-format';
import { MuiNumberInput } from '@components/controls/react-hook-form-friendly/smart/MuiNumberInput';
import { SolarEnergyProjectValues } from '@pages/NewLeads/project/solarEnergyProject/solarEnergyProjectZodSchema';

type CustomSolarPanelInputsProps = {
  roofIndex: number;
};
export const CustomSolarPanelInputs: React.FC<CustomSolarPanelInputsProps> = ({ roofIndex }) => {
  const {
    translate,
    translations: {
      leads: {
        details: {
          project: {
            addEditPage: {
              solarEnergy: { panelPower, panelHeight, panelWidth, panelWeight },
            },
          },
        },
      },
      common: { units },
    },
  } = useTranslations();

  const { control } = useFormContext<SolarEnergyProjectValues>();
  const { field: height, fieldState: heightState } = useController({
    control,
    name: `roofs.${roofIndex}.customSolarPanel.height`,
  });
  const { field: width, fieldState: widthState } = useController({
    control,
    name: `roofs.${roofIndex}.customSolarPanel.width`,
  });

  const [fieldValues, setFieldValues] = React.useState({
    height: height.value,
    width: width.value,
  });

  const handleFieldChange = (fieldName: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValues((prev) => ({ ...prev, [fieldName]: e.target.value }));
  };

  return (
    <Stack spacing={2}>
      <MuiNumberInput
        control={control}
        name={`roofs.${roofIndex}.customSolarPanel.outputPower`}
        label={translate(panelPower)}
        required
        InputProps={{
          endAdornment: <InputAdornment position="end">{translate(units.wattPeak)}</InputAdornment>,
        }}
      />
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <NumericFormat
          defaultValue={null}
          customInput={TextFieldCore}
          value={fieldValues.width}
          label={translate(panelWidth)}
          required
          InputProps={{
            endAdornment: <InputAdornment position="end">{translate(units.milliMeter)}</InputAdornment>,
          }}
          onChange={handleFieldChange(`width`)}
          onBlur={() => width.onChange(fieldValues.width)}
          error={!!widthState.error}
          decimalSeparator=","
          decimalScale={0}
        />
        <NumericFormat
          defaultValue={null}
          customInput={TextFieldCore}
          value={fieldValues.height}
          label={translate(panelHeight)}
          required
          InputProps={{
            endAdornment: <InputAdornment position="end">{translate(units.milliMeter)}</InputAdornment>,
          }}
          onChange={handleFieldChange(`height`)}
          onBlur={() => height.onChange(fieldValues.height)}
          error={!!heightState.error}
          decimalSeparator=","
          decimalScale={0}
        />
      </Stack>
      <MuiNumberInput
        control={control}
        name={`roofs.${roofIndex}.customSolarPanel.weight`}
        label={translate(panelWeight)}
        required
        InputProps={{
          endAdornment: <InputAdornment position="end">{translate(units.kiloGram)}</InputAdornment>,
        }}
      />
    </Stack>
  );
};
