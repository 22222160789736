import { FC, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { RoofMaterialsChips } from './RoofMaterialsChips';
import { AdditionalRoofInputs } from './AdditionalRoofInputs';
import { useEffectOnRoofMaterialChange } from './useEffectOnRoofMaterialChange';
import { useGetSolarEnergyProject } from '@services/api/solarEnergyProjects/solarEnergyProjects';
import { useToggle } from '@hooks/useToggle';
import { useGetSolarEnergyProjectSettings } from '@services/api/solarEnergyProjects/solarEnergyProjectsSettings';
import { getValuesFromAttributeRow } from '@pages/NewLeads/project/solarEnergyProject/utils/utils';
import { SolarEnergyProjectValues } from '@pages/NewLeads/project/solarEnergyProject/solarEnergyProjectZodSchema';

interface RoofMaterialsWrapperProps {
  roofIndex: number;
}

export const RoofMaterialsWrapper: FC<RoofMaterialsWrapperProps> = ({ roofIndex }) => {
  const {
    translate,
    translations: {
      common: { basis },
    },
  } = useTranslations();
  const { solarEnergyProjectDetails: projectData } = useGetSolarEnergyProject();
  const { roofMaterial, roofMaterialGroups } = useGetSolarEnergyProjectSettings();
  const roofMaterialValues = getValuesFromAttributeRow(roofMaterial);
  const roofMaterialGroupsValues = getValuesFromAttributeRow(roofMaterialGroups);
  const context = useFormContext<SolarEnergyProjectValues>();
  const [isTabTouched, toggleIsTabTouched] = useToggle();

  const [selectedTab, setSelectedTab] = useState<number>();

  // set selected tab if roofMaterialId is set, if not set first tab
  useEffect(() => {
    const roofIdFromForm = context.getValues().roofs?.[roofIndex]?.roofMaterialId;
    if (roofIdFromForm) {
      const roofMaterialGroup = roofMaterialValues.find(({ id }) => id === roofIdFromForm)?.roofMaterialGroupId;
      setSelectedTab(roofMaterialGroup);
    } else if (projectData?.roofs?.[roofIndex]?.roofMaterialId) {
      const roofMaterialGroup = roofMaterialValues.find(
        ({ id }) => id === projectData.roofs[roofIndex].roofMaterialId,
      )?.roofMaterialGroupId;

      setSelectedTab(roofMaterialGroup);
    } else {
      setSelectedTab(roofMaterialGroupsValues[0]?.id);
    }
    // retrigger only on roofMaterialsGroups change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roofMaterialGroups]);

  const filteredRoofMaterial = useMemo(
    () =>
      roofMaterialValues
        .filter(
          ({ name, imageUri, roofMaterialGroupId, id }) =>
            roofMaterialGroupId === selectedTab && name && imageUri && id,
        )
        .map(({ name, imageUri, id }) => ({ title: name as string, src: imageUri as string, value: id })),
    [roofMaterialValues, selectedTab],
  );

  useEffectOnRoofMaterialChange({
    roofIndex,
    selectedTab,
    defaultValue: filteredRoofMaterial[0]?.value,
    isTabTouched,
  });

  if (!filteredRoofMaterial.length) return null;

  return (
    <>
      <RoofMaterialsChips
        control={context.control}
        roofMaterials={filteredRoofMaterial}
        selectedTab={selectedTab || 0}
        roofMaterialGroups={roofMaterialGroupsValues}
        roofIndex={roofIndex}
        setSelectedTab={setSelectedTab}
        materialsLabel={translate(basis)}
        toggleIsTabTouched={toggleIsTabTouched}
        isTabTouched={isTabTouched}
      />
      <AdditionalRoofInputs control={context.control} roofIndex={roofIndex} />
    </>
  );
};
