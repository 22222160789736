import './resetPassword.css';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { useSearchParams } from '@utils/useSearchParams';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { InputWrapper } from '@components/controls/react-hook-form-friendly/smart';
import { requiredString } from '@variables/zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useResetPassword } from '@services/api/accounts';
import { Box, Button, Stack, Typography } from '@mui/material';
import { RexelLogo } from '@components/rexelLogo/RexelLogo';

const passwordValidationPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*\d)/;
const passwordIsValid = (password: string) => passwordValidationPattern.test(password);

const useResetPasswordZodSchema = () => {
  const {
    translations: {
      validationErrors: { passwordFormatIncorrect, passwordDontMatch },
    },
    translate,
  } = useTranslations();

  return z
    .object({
      newPassword: requiredString().refine((value) => passwordIsValid(value), {
        message: translate(passwordFormatIncorrect),
      }),
      confirmPassword: requiredString(),
    })
    .refine(({ newPassword, confirmPassword }) => newPassword === confirmPassword, {
      message: translate(passwordDontMatch),
      path: [`confirmPassword`],
    });
};

function NoParams() {
  const { translations, translate } = useTranslations();
  const tr = translations.resetPassword;

  return (
    <div className="box box-large col-1-mobile-small col-desktop-4 col-desktop-offset-5 col-12">
      <div className="column fw fh gap-big align-center">
        <h2>{translate(tr.invalidLink)}</h2>
        <span>{translate(tr.invalidLinkMessage)}</span>
      </div>
    </div>
  );
}

export const ResetPassword = () => {
  const { translations, translate, translateWithParams } = useTranslations();
  const btr = translations.common.buttons;
  const tr = translations.resetPassword;
  const searchParams = useSearchParams();
  const email = useMemo(() => searchParams.get(`email`) ?? ``, [searchParams]);
  const token = useMemo(() => searchParams.get(`token`)?.replace(/ /g, `+`) ?? ``, [searchParams]);
  const resetPasswordZodSchema = useResetPasswordZodSchema();

  const { resetPassword, isResettingPassword } = useResetPassword();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(resetPasswordZodSchema),
    values: { newPassword: ``, confirmPassword: `` },
  });

  if (!email || !token) {
    return <NoParams />;
  }

  return (
    <Stack
      sx={(theme) => ({
        backgroundColor: theme.palette.background.default,
        width: `100%`,
        height: `100%`,
      })}
      justifyContent="center"
      alignItems="center"
    >
      <Box
        sx={{
          position: `absolute`,
          top: 0,
          left: 0,
          marginRight: `auto`,
          marginLeft: 7,
          marginTop: 6,
        }}
      >
        <RexelLogo />
      </Box>
      <Box className="fw fh column col-1-mobile-small col-4-desktop" top="25%" position="absolute">
        <form
          className="column left-center gap gap-small fw"
          onSubmit={handleSubmit(({ newPassword }) => resetPassword({ email, token, password: newPassword }))}
        >
          <Stack spacing={3}>
            <Typography variant="h1" textAlign="left" mr="auto">
              {translateWithParams(tr.changePasswordFor)(email)}
            </Typography>

            <Stack spacing={2}>
              <InputWrapper
                control={control}
                label={translate(tr.newPassword)}
                type="text"
                name="newPassword"
                errorMessage={errors.newPassword?.message}
                isRequired
              />
              <InputWrapper
                control={control}
                label={translate(tr.confirmNewPassword)}
                type="password"
                name="confirmPassword"
                errorMessage={errors.confirmPassword?.message}
                isRequired
              />
            </Stack>
            <Button type="submit" variant="contained" disabled={isResettingPassword}>
              {translate(btr.save)}
            </Button>
          </Stack>
        </form>
      </Box>
    </Stack>
  );
};
