import { useNotifications } from '@services/hooks/notifications/useNotifications';
import { selectFeatureEnabled } from '@redux/reducers/slices/user';
import { useSelector } from 'react-redux';

const NotificationsHandlerComponent = () => {
  useNotifications();

  return null;
};

export const NotificationsHandler = () => {
  const systemMessagesEnabled = useSelector(selectFeatureEnabled(`system-message`));

  return systemMessagesEnabled ? <NotificationsHandlerComponent /> : null;
};
