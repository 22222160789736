import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { signIn } from '@services/api/authenticate/signIn';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { InputWrapper } from '@components/controls/react-hook-form-friendly/smart';
import { requiredString } from '@variables/zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useToggle } from '@hooks/useToggle';
import { useSnackbar } from 'notistack';
import { Box, Button, Link, Stack, Typography } from '@mui/material';
import { RexelLogo } from '@components/rexelLogo/RexelLogo';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from '@redux/reducers/slices/user';
import { saveTokenToStorage } from '@services/hooks/authentication/utils';

const loginZodSchema = z.object({
  userName: requiredString(),
  password: requiredString(),
  rememberMe: z.boolean(),
});

type LoginValuesType = z.infer<typeof loginZodSchema>;

export const Login: React.FC = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { enqueueSnackbar } = useSnackbar();
  const {
    translations: { login, forgotPassword },
    translate,
  } = useTranslations();
  const navigate = useNavigate();
  const location = useLocation();
  const [disableSubmitButton, toggleDisableSubmitButton] = useToggle();

  const { control, handleSubmit } = useForm<LoginValuesType>({
    resolver: zodResolver(loginZodSchema),
    values: { userName: ``, password: ``, rememberMe: false },
  });

  useEffect(() => {
    if (isLoggedIn) {
      navigate(location.state?.from || `/`);
    }
  }, [location.state?.from, navigate, isLoggedIn]);

  const handleLoginSubmit = useCallback(
    async ({ userName, password, rememberMe }: LoginValuesType) => {
      toggleDisableSubmitButton();
      try {
        const response = await signIn({ userName, password, rememberMe });
        saveTokenToStorage(response);
      } catch (error) {
        const err = error as string;

        enqueueSnackbar(err, { variant: `error`, anchorOrigin: { horizontal: `right`, vertical: `top` } });
      } finally {
        toggleDisableSubmitButton();
      }
    },
    [toggleDisableSubmitButton, enqueueSnackbar],
  );

  return (
    <Stack
      sx={(theme) => ({
        backgroundColor: theme.palette.background.default,
        width: `100%`,
        height: `100%`,
      })}
      justifyContent="center"
      alignItems="center"
    >
      <Box
        sx={{
          position: `absolute`,
          top: 0,
          left: 0,
          marginRight: `auto`,
          marginLeft: 7,
          marginTop: 6,
        }}
      >
        <RexelLogo />
      </Box>
      <Box className="fw fh column col-1-mobile-small col-4-desktop" top="25%" position="absolute">
        <form className="column left-center gap gap-small fw" onSubmit={handleSubmit(handleLoginSubmit)}>
          <Stack spacing={3}>
            <Typography variant="h1" textAlign="left" mr="auto">
              {translate(login.header)}
            </Typography>

            <Stack spacing={2}>
              <InputWrapper
                control={control}
                label={translate(login.username)}
                type="text"
                name="userName"
                placeholder={translate(forgotPassword.email)}
              />
              <InputWrapper
                control={control}
                label={translate(login.password)}
                type="password"
                name="password"
                placeholder={translate(login.password)}
              />
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              {/* This feature is disabled until Rexel support won't assign the domain for backend server - PBI-7187 */}
              {/*<Box>*/}
              {/*  <SingleCheckbox*/}
              {/*    control={control}*/}
              {/*    name={`rememberMe`}*/}
              {/*    value={`rememberMe`}*/}
              {/*    label={translate(login.rememberMe)}*/}
              {/*    isLabelOnRight*/}
              {/*  />*/}
              {/*</Box>*/}
              <Link sx={{ textUnderlineOffset: 5 }} href="/forgot-password">
                <Typography>{translate(login.resetPassword)}</Typography>
              </Link>
            </Stack>
            <Button type="submit" variant="contained" disabled={disableSubmitButton}>
              {translate(login.login)}
            </Button>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
              <Typography variant="body2">{translate(login.createAccountLabel)}</Typography>
              <Link sx={{ textUnderlineOffset: 5 }} href="https://www.rexel.se/swe/myenergy">
                <Typography>{translate(login.createAccountButton)}</Typography>
              </Link>
            </Stack>
          </Stack>
        </form>
      </Box>
    </Stack>
  );
};
