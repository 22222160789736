import { CreateProjectProductRequest, ProjectProductOrigin } from '@generatedTypes/data-contracts';
import { AnalyticEvents } from '@hooks/useAnalytics/analyticEvents';
import { useAnalytics } from '@hooks/useAnalytics/useAnalytics';
import { useToggle } from '@hooks/useToggle';
import { Drawer } from '@mui/material';
import { setCurrentProductsAttributesFilters } from '@redux/actions/lead';
import { selectCurrentProductsAttributesFilters, selectCurrentProjectId } from '@redux/selectors/lead';
import { useGetLeadProjects } from '@services/api/leads/lead-info';
import { useGetOffer } from '@services/api/offers/offers';
import { useGetProductCategory } from '@services/api/productCategories/productCategories';
import { useCreateProjectProductMutation } from '@services/api/projectProducts/ProjectProductAdd';
import {
  PutProjectProductProps,
  useUpdateProjectProductMutation,
} from '@services/api/projectProducts/projectProductEdit';
import { useGetProjectProductById } from '@services/api/projectProducts/projectProductGet';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EditProjectProduct } from '@pages/NewLeads/Projects/ProductsAdditionsAndDeductions/projectProducts/editProjectProduct';
import { ProductAttributeFilters } from '@pages/NewLeads/Projects/ProductsAdditionsAndDeductions/projectProducts/productsAttributeFilters/productsAttributeFilters';
import { getAppliedFiltersFromProduct } from '@pages/NewLeads/Projects/ProductsAdditionsAndDeductions/projectProducts/productsAttributeFilters/utils/externalComponentsUtils/leadsUtils';
import { AppliedFilters } from '@pages/NewLeads/Projects/ProductsAdditionsAndDeductions/projectProducts/productsAttributeFilters/utils/types';

export interface EditProjectProductWrapperProps {
  onClose: () => void;
}

export const EditProjectProductWrapper = ({ onClose }: EditProjectProductWrapperProps) => {
  const pushDataLayer = useAnalytics();
  const projectProductId = useSelector(selectCurrentProjectId);
  const dispatch = useDispatch();
  const productCategoryFilters = useSelector(selectCurrentProductsAttributesFilters);
  const [areFiltersOpen, toggleAreFiltersOpen] = useToggle();
  const { isOfferLocked } = useGetOffer();
  const { projectProduct, isLoadingProjectProduct } = useGetProjectProductById();
  const { leadProjects, refetch: refetchLeadsProjects } = useGetLeadProjects();
  const productCategory = useGetProductCategory();
  const { updateProjectProduct, isUpdatingProjectProduct, updateProjectProductBeValidationErrors } =
    useUpdateProjectProductMutation();

  const { addProjectProduct, isAddingProjectProduct, addProjectProductBeValidationErrors } =
    useCreateProjectProductMutation();

  const productUserOrigin = projectProduct?.origin !== ProjectProductOrigin.Template;
  const filtersToUse = useMemo(
    () => (productUserOrigin ? productCategoryFilters : getAppliedFiltersFromProduct(projectProduct, productCategory)),
    [projectProduct, productCategory, productCategoryFilters, productUserOrigin],
  );

  const onSubmitProjectProduct = useCallback(
    (projectProduct: PutProjectProductProps | CreateProjectProductRequest) => {
      if (Object.hasOwn(projectProduct, `id`)) {
        pushDataLayer({
          event: AnalyticEvents.materialChange,
          projectType: leadProjects?.find((project) => project.id === projectProductId)?.type,
        });
        updateProjectProduct(projectProduct as PutProjectProductProps);
        onClose();
      }
      if (Object.hasOwn(projectProduct, `projectId`)) {
        pushDataLayer({
          event: AnalyticEvents.materialAdd,
          projectType: leadProjects?.find((project) => project.id === projectProductId)?.type,
        });
        addProjectProduct(projectProduct as CreateProjectProductRequest);
        onClose();
      }
      dispatch(setCurrentProductsAttributesFilters([]));
    },
    [dispatch, pushDataLayer, leadProjects, updateProjectProduct, onClose, projectProductId, addProjectProduct],
  );

  const onSubmitProjectAttributeFilters = useCallback(
    (newFilters: AppliedFilters) => {
      dispatch(setCurrentProductsAttributesFilters(newFilters));
      toggleAreFiltersOpen();
    },
    [dispatch, toggleAreFiltersOpen],
  );

  const onCloseFilters = useCallback(() => {
    dispatch(setCurrentProductsAttributesFilters([]));
    if (areFiltersOpen) {
      toggleAreFiltersOpen();
    }
  }, [dispatch, toggleAreFiltersOpen, areFiltersOpen]);

  const onCloseEditPanel = useCallback(() => {
    onClose();
    dispatch(setCurrentProductsAttributesFilters([]));
  }, [dispatch, onClose]);

  return (
    <>
      <EditProjectProduct
        closeEditPanel={onCloseEditPanel}
        onSubmitProjectProduct={onSubmitProjectProduct}
        onOpenProductAttributeFilters={productUserOrigin ? toggleAreFiltersOpen : undefined}
        disableForm={isUpdatingProjectProduct || isAddingProjectProduct || isOfferLocked}
        beValidationResults={addProjectProductBeValidationErrors || updateProjectProductBeValidationErrors}
        refetchLeadProjects={refetchLeadsProjects}
        filters={filtersToUse}
        isLoading={isLoadingProjectProduct}
      />
      <Drawer open={areFiltersOpen} onClose={onCloseFilters} anchor="right" hideBackdrop>
        <ProductAttributeFilters
          productCategory={productCategory}
          initialFilters={productCategoryFilters}
          submitFilters={onSubmitProjectAttributeFilters}
          onCancel={onCloseFilters}
        />
      </Drawer>
    </>
  );
};
