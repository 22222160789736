import { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { ChipList } from '@components/controls/react-hook-form-friendly/smart/ChipList';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { RoofMaterialAttributeValueDto } from '@generatedTypes/data-contracts';
import { useEffectOnRoofPropertiesChange } from './useEffectOnRoofPropertiesChange';
import { useGetSolarEnergyProjectSettings } from '@services/api/solarEnergyProjects/solarEnergyProjectsSettings';
import { getValuesFromAttributeRow } from '@pages/NewLeads/project/solarEnergyProject/utils/utils';
import { SolarEnergyProjectValues } from '@pages/NewLeads/project/solarEnergyProject/solarEnergyProjectZodSchema';

interface SteelOrAluminiumThicknessProps {
  roofIndex: number;
  roofMaterial: RoofMaterialAttributeValueDto;
}

export const SteelOrAluminiumThickness: FC<SteelOrAluminiumThicknessProps> = ({ roofIndex, roofMaterial }) => {
  const {
    translate,
    translations: {
      leads: {
        details: {
          project: {
            addEditPage: {
              solarEnergy: { sheetThickness, ticknessAluminium },
            },
          },
        },
      },
    },
  } = useTranslations();
  const { tinOrMetalThickness, roofMaterialGroups } = useGetSolarEnergyProjectSettings();
  const formContext = useFormContext<SolarEnergyProjectValues>();

  const { availableTinOrMetalThicknessIds } = roofMaterial;
  const filteredTinOrMetalThickness = useMemo(
    () =>
      getValuesFromAttributeRow(tinOrMetalThickness)
        .filter(({ id }) => availableTinOrMetalThicknessIds?.includes(id))
        .map(({ name, id }) => ({ label: name as string, value: id })),
    [availableTinOrMetalThicknessIds, tinOrMetalThickness],
  );
  const isAluminium =
    getValuesFromAttributeRow(roofMaterialGroups).find(({ id }) => id === roofMaterial.roofMaterialGroupId)?.name ===
    `Aluminium`;
  const label = isAluminium ? translate(ticknessAluminium) : translate(sheetThickness);

  useEffectOnRoofPropertiesChange({
    roofIndex,
    path: `tinOrMetalThicknessId`,
    defaultValue: filteredTinOrMetalThickness[0]?.value,
  });

  if (availableTinOrMetalThicknessIds?.length) {
    return (
      <ChipList
        key={filteredTinOrMetalThickness[0]?.value}
        label={label}
        version="large"
        name={`roofs.${roofIndex}.tinOrMetalThicknessId`}
        control={formContext.control}
        chips={filteredTinOrMetalThickness}
      />
    );
  }

  return null;
};
