import { Root } from '@assets/translations/translations';
import { StringWithParams } from '@assets/translations/typeUtils';
import { AppState } from '@redux/reducers';
import { defaultLanguage, GlobalState, ImplementedLanguages, initState } from '@redux/reducers/global';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

type AllTranslation = {
  [key in ImplementedLanguages]: string;
};

export type Translation = Omit<Partial<AllTranslation>, typeof defaultLanguage> & {
  [defaultLanguage]: string;
};

export type TranslationWithParams = {
  [key in ImplementedLanguages]: StringWithParams;
};

function isStringWithParams(value: string): value is StringWithParams {
  return value.includes(`{`) && value.includes(`}`);
}

function assertTranslationWithParams(translation: unknown): asserts translation is TranslationWithParams {
  if (
    typeof translation === `object` &&
    isStringWithParams((translation as TranslationWithParams)[initState.defaultLanguage])
  ) {
    return;
  }
  throw new Error(
    `Translation must be an object with a string property of the default language - ${initState.defaultLanguage}`,
  );
}

export const useTranslations = () => {
  const translations = useSelector<AppState, Root>((state) => state.Global.translations);
  const defaultLanguage = useSelector<AppState, GlobalState[`defaultLanguage`]>(
    (state) => state.Global.defaultLanguage,
  );
  const chosenLanguage = useSelector<AppState, ImplementedLanguages>((state) => state.Global.chosenLanguage);

  const translate = useCallback(
    (translation: Translation | null) =>
      translation ? translation[chosenLanguage] || translation[defaultLanguage] : ``,
    [chosenLanguage, defaultLanguage],
  );

  const translateWithParams = useCallback(
    (translation: TranslationWithParams) => {
      assertTranslationWithParams(translation);
      const translationText = translation[chosenLanguage];

      const params = translationText.match(/\{(.*?)\}/g);
      const paramsCount = params ? params.length : 0;
      const translationFunction = (...props: (string | number)[]) => {
        let result = ``;
        for (let i = 0; i < paramsCount; i++) {
          result = translationText.replace(`${params?.[i]}`, String(props[i]));
        }
        return result;
      };

      return translationFunction;
    },
    [chosenLanguage],
  );

  return {
    translations,
    translateWithParams,
    translate,
  };
};
