import { ListItem } from '@components/ListMui/ListItem';
import { SxProps } from '@mui/system';
import { getFormattedNumberWithStaticFraction, getFormattedPriceWithStaticFraction } from '@pages/NewLeads/utils';
import { Stack, Typography, useTheme } from '@mui/material';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { LinkButton } from '@components/button/LinkButton';
import { setPackageDataToDetailsModal } from '@redux/reducers/slices/leadsPage';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';

export type ProductListItemProps = {
  key: string | number;
  product: {
    id: number;
    productId?: number | null;
    productName: string;
    manufacturerName?: string | null;
    productCategoryName: string;
    isExcluded?: boolean;
    quantity: number;
    productNetPrice: number;
    unit: string;
    isPackage?: boolean;
    isUnavailable?: boolean;
  };
  onClick?: () => void | undefined;
  contextMenu?: React.ReactNode;
  sx?: SxProps;
  showPrices?: boolean;
};

export const ProductListItem: React.FC<ProductListItemProps> = ({
  product,
  onClick,
  sx,
  contextMenu,
  showPrices = true,
}) => {
  const {
    translate,
    translateWithParams,
    translations: {
      leads: {
        details: {
          project: {
            productList: { excludedLabel, productUnavailable },
          },
        },
      },
    },
  } = useTranslations();
  const theme = useTheme();

  const dispatch = useDispatch();

  const productNameLabel = `${product.productName}${product.manufacturerName ? `, ${product.manufacturerName}` : ``}`;

  const subtitle = useMemo(() => {
    if (product.isUnavailable) {
      return (
        <Typography variant="subtitle1" color={theme.palette.error.dark}>
          {translateWithParams(productUnavailable)(product.productCategoryName)}
        </Typography>
      );
    }
    return product.isPackage && product.productId ? (
      <LinkButton
        onClick={() => {
          dispatch(
            setPackageDataToDetailsModal({
              id: product.productId ?? -1,
              name: product.productName,
              categoryName: product.productCategoryName,
            }),
          );
        }}
      >
        {productNameLabel}
      </LinkButton>
    ) : (
      productNameLabel
    );
  }, [
    product.isUnavailable,
    product.isPackage,
    product.productId,
    product.productName,
    product.productCategoryName,
    productNameLabel,
    theme.palette.error.dark,
    translateWithParams,
    productUnavailable,
    dispatch,
  ]);

  if (!product.productName || !product.id) {
    return null;
  }

  return (
    <ListItem
      key={`product-item-${product.id}-${product.productName}`}
      onClick={onClick}
      title={product.productCategoryName}
      subtitle={subtitle}
      parentSx={{
        ...(product.isExcluded ? { color: theme.palette.rexelEnergy.grey } : {}),
        [theme.breakpoints.up(`lg`)]: {
          '& #-button:not([aria-expanded="true"])': {
            opacity: 0,
            visibility: `hidden`,
            transition: `opacity 0.2s ease-in-out`,
          },
        },
        [theme.breakpoints.down(`lg`)]: {
          '& #-button:not([aria-expanded="true"])': {
            backgroundColor: `white`,
          },
        },
        '&:hover #-button:not([aria-expanded="true"])': {
          opacity: 100,
          visibility: `visible`,
          backgroundColor: theme.palette.rexelEnergy.beige,
        },
      }}
      secondaryAction={
        <Stack direction="row-reverse" spacing={1} width="7.8rem" justifyContent="space-between" alignItems="center">
          <Stack flex={3}>
            <Typography
              textAlign="right"
              color={product.isExcluded ? theme.palette.rexelEnergy.grey : theme.palette.rexelEnergy.blackBlue}
              sx={{
                textWrap: `nowrap`,
                direction: product.isExcluded ? `rtl` : `ltr`,
                overflow: `visible`,
                maxWidth: `100%`,
              }}
            >
              {product.isExcluded
                ? translate(excludedLabel)
                : getFormattedNumberWithStaticFraction(product.quantity, null, product.unit)}
            </Typography>
            {!!product.productNetPrice && showPrices && (
              <Typography variant="subtitle1" textAlign="right" noWrap>
                {getFormattedPriceWithStaticFraction(product.productNetPrice, 0)}
              </Typography>
            )}
          </Stack>
          {contextMenu}
        </Stack>
      }
      sx={sx}
    />
  );
};
