import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { SolarEnergyProjectValues } from '@pages/NewLeads/project/solarEnergyProject/solarEnergyProjectZodSchema';

export interface UseChangeValueOnTabChange {
  roofIndex: number;
  selectedTab?: number;
  defaultValue: number;
  isTabTouched?: boolean;
}

export const useEffectOnRoofMaterialChange = ({
  roofIndex,
  selectedTab,
  isTabTouched,
  defaultValue,
}: UseChangeValueOnTabChange) => {
  const { setValue } = useFormContext<SolarEnergyProjectValues>();

  useEffect(() => {
    if (isTabTouched) {
      setValue(`roofs.${roofIndex}.roofMaterialId`, defaultValue);
    }
  }, [defaultValue, isTabTouched, roofIndex, selectedTab, setValue]);
};
