import { ProductAttributeFilterType, ProductCategoryAttributeRowDto } from '@generatedTypes/data-contracts';
import { AppliedFilters, ProductAttributeRowNumberProps, ProductsAttributeFiltersSchemaType } from '../types';

const getValueForRange = (value: number, min: number, max: number) => {
  if (value < min) {
    return min;
  }
  if (value > max) {
    return max;
  }
  return value;
};

export const getRangesFromNumericAttribute = (attribute: ProductCategoryAttributeRowDto, limitValues?: number[]) => ({
  min: limitValues ? Math.min(...limitValues) : Number(attribute.values[0].name),
  max: limitValues ? Math.max(...limitValues) : Number(attribute.values.at(-1)?.name),
});

type GetFieldValuesProps = {
  attribute: ProductCategoryAttributeRowDto;
  valuesAvailableForAttribute: Set<number> | undefined;
  appliedFilter?: ProductAttributeRowNumberProps;
  initialFilter?: ProductAttributeRowNumberProps;
};

export const getFormFilterForNumberTypeFilter = ({
  attribute,
  initialFilter,
  appliedFilter,
  valuesAvailableForAttribute,
}: GetFieldValuesProps): ProductsAttributeFiltersSchemaType[number] => {
  const availableValuesForAttributeArray = Array.from(valuesAvailableForAttribute?.values() ?? []);
  const valueRanges = getRangesFromNumericAttribute(attribute, availableValuesForAttributeArray);
  const value = getValueForRange(
    appliedFilter?.value ?? initialFilter?.value ?? valueRanges.min,
    valueRanges.min,
    valueRanges.max,
  );
  return {
    type: ProductAttributeFilterType.Number,
    attributeName: attribute.name ?? ``,
    active: appliedFilter?.active ?? false,
    attributeId: attribute.id,
    value,
    valueName: String(value),
    min: valueRanges.min,
    max: valueRanges.max,
  };
};

export const testNumberAttributeWithFilters = ({
  attribute,
  filters,
}: {
  attribute: ProductCategoryAttributeRowDto;
  filters: AppliedFilters;
}) => {
  const { min: valueFromProduct } = getRangesFromNumericAttribute(attribute);
  return filters.some(({ value }) => valueFromProduct >= value);
};
