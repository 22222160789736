import { showNext, VIEW_TYPES } from '@components/columnLayout/utils';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Link, Stack, Typography } from '@mui/material';
import { RexelLogo } from '@components/rexelLogo/RexelLogo';

export function ForgotPasswordConfirmation() {
  const { email } = useParams();
  const {
    translate,
    translations: {
      resetPasswordConfirmation: { description, emailSent, header, backToLogin },
    },
  } = useTranslations();

  useEffect(() => {
    showNext(VIEW_TYPES.INFO)();
  }, []);

  return (
    <Stack
      sx={(theme) => ({
        backgroundColor: theme.palette.background.default,
        width: `100%`,
        height: `100%`,
      })}
      justifyContent="center"
      alignItems="center"
    >
      <Box
        sx={{
          position: `absolute`,
          top: 0,
          left: 0,
          marginRight: `auto`,
          marginLeft: 7,
          marginTop: 6,
        }}
      >
        <RexelLogo />
      </Box>
      <Box className="fw fh column col-1-mobile-small col-4-desktop" top="25%" position="absolute">
        <Stack spacing={2}>
          <Typography variant="h1">{translate(header)}</Typography>

          <Typography>
            {translate(emailSent)} {email}
          </Typography>
          <Typography>{translate(description)}</Typography>
          <Link sx={{ textUnderlineOffset: 5 }} href="/login">
            {translate(backToLogin)}
          </Link>
        </Stack>
      </Box>
    </Stack>
  );
}
