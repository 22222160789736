import { CenteredCircularProgress } from '@components/Loading/CenteredCircularProgress';
import { ProjectType } from '@generatedTypes/data-contracts';
import { AnalyticEvents } from '@hooks/useAnalytics/analyticEvents';
import { useAnalytics } from '@hooks/useAnalytics/useAnalytics';
import { Box } from '@mui/material';
import { SolarEnergyProjectForm, FormInputs } from '@pages/NewLeads/project/solarEnergyProject/SolarEnergyProject';
import { useGetLead } from '@services/api/leads/lead-info';
import { useGetOffer } from '@services/api/offers/offers';
import {
  useDeleteSolarEnergyProject,
  useGetSolarEnergyProject,
  usePostSolarEnergyProject,
  usePutSolarEnergyProject,
} from '@services/api/solarEnergyProjects/solarEnergyProjects';
import { useSolarEnergyFormDataProvider } from '@pages/NewLeads/project/solarEnergyProject/utils/useSolarEnergyFormDataProvider';
import { getMountingMaterialMappedAsObject } from '@pages/NewLeads/project/solarEnergyProject/roofVisualisation/utils/useSolarMapVisualisation/dataUtils';
import { useGetSolarEnergyProjectSettings } from '@services/api/solarEnergyProjects/solarEnergyProjectsSettings';
import { useQueryClient } from 'react-query';
import { LEADS } from '@variables/queryKeys';
import { useQueryParamsModal } from '@utils/navigation/queryParamsNavigation';

export interface SolarEnergyFormProps {
  onClose: () => void;
}

export const SolarEnergyForm = ({ onClose }: SolarEnergyFormProps) => {
  const queryClient = useQueryClient();
  const pushDataLayer = useAnalytics();
  useSolarEnergyFormDataProvider();

  const { lead } = useGetLead();
  const { solarEnergyProjectDetails, isLoading } = useGetSolarEnergyProject();
  const { mountingMaterialManufacturer } = useGetSolarEnergyProjectSettings();
  const { createSolarEnergyProjectAsync, validationErrors, isCreateSolarEnergyProjectLoading } =
    usePostSolarEnergyProject();
  const {
    updateSolarEnergyProjectAsync,
    isUpdateSolarEnergyProjectLoading,
    validationErrors: updateSolarEnergyProjectError,
  } = usePutSolarEnergyProject();
  const { deleteSolarEnergyProjectAsync } = useDeleteSolarEnergyProject();
  const { isOfferLocked } = useGetOffer();

  const { options } = useQueryParamsModal(`project`);
  const mountingMaterials = getMountingMaterialMappedAsObject(mountingMaterialManufacturer);

  const handleAddEditSolarEnergyProject = (values: FormInputs) => {
    const currentProjectId = Number(options.projectId);
    pushDataLayer({
      event: AnalyticEvents.saveProject,
      projectType: `Solanläggning`,
      materialSupplier: values.roofs
        .map((roof) => mountingMaterials[roof.mountingMaterialManufacturerId as number])
        .join(`, `),
    });
    if (currentProjectId && !isNaN(currentProjectId)) {
      updateSolarEnergyProjectAsync({
        ...values,
        id: currentProjectId,
        projectProductTemplateId: values.projectProductTemplateId,
        snowLoad: values.snowLoad ?? 0,
        windLoad: values.windLoad ?? 0,
      }).then(() => {
        onClose();
      });
    } else {
      createSolarEnergyProjectAsync({
        ...values,
        leadId: lead?.id ?? 0,
        projectProductTemplateId: values.projectProductTemplateId,
        snowLoad: values.snowLoad ?? 0,
        windLoad: values.windLoad ?? 0,
      }).then(() => {
        onClose();
      });
    }

    queryClient.invalidateQueries(LEADS);
  };

  const handleDeleteSolarEnergyProject = (id: number) => {
    deleteSolarEnergyProjectAsync(id).then(() => {
      onClose();
    });
  };

  if (isLoading)
    return (
      <Box display="grid" height="100%" sx={{ placeItems: `center` }}>
        <CenteredCircularProgress />
      </Box>
    );

  return (
    <SolarEnergyProjectForm
      onSubmit={handleAddEditSolarEnergyProject}
      onDelete={handleDeleteSolarEnergyProject}
      onClose={() => {
        onClose();
        pushDataLayer({ event: AnalyticEvents.cancelProject, projectType: ProjectType.SolarEnergy });
      }}
      projectDetails={solarEnergyProjectDetails}
      leadPropertyDetails={lead?.propertyInfo ?? null}
      leadAddress={lead?.address ?? null}
      beValidationResults={validationErrors || updateSolarEnergyProjectError}
      isDisabled={isCreateSolarEnergyProjectLoading || isUpdateSolarEnergyProjectLoading || isOfferLocked}
      isLoading={isLoading}
    />
  );
};
