import {
  OfferDto,
  LeadOfferStatus,
  ProjectProductTemplateDto,
  ProductAttributeValueRowDto,
} from '@generatedTypes/data-contracts';
import { DropdownOption } from '@hooks/useForm/useFormTypes';

export const mapAttributeRowToOption = (
  attributeRow: ProductAttributeValueRowDto | ProjectProductTemplateDto,
): DropdownOption<number> => ({
  value: Number(attributeRow.id),
  label: String(attributeRow.name),
});

export const isOfferLocked = (offer: OfferDto | null) =>
  [
    LeadOfferStatus.Projected,
    LeadOfferStatus.Sent,
    LeadOfferStatus.Accepted,
    LeadOfferStatus.Lost,
    LeadOfferStatus.Expired,
  ].includes(offer?.status ?? LeadOfferStatus.Created);
