import { Patch } from '@pages/NewLeads/project/solarEnergyProject/roofVisualisation/roofVisualisationTypes';
import { selectEdge } from '../../edge';
import { useHandleUpdatePatch } from './patch';
import { getVerticesWithUpdatedLength } from '../utils';
import { useFormContext } from 'react-hook-form';
import { useSelectedRoofAndPatch } from '../../accessors';
import { SolarEnergyProjectValues } from '@pages/NewLeads/project/solarEnergyProject/solarEnergyProjectZodSchema';

export const useHandleEdgeSelect = () => {
  const handleUpdatePatch = useHandleUpdatePatch();
  return (edgeId: string, patch: Patch) => () => {
    handleUpdatePatch(selectEdge(edgeId, patch));
  };
};

export const useHandleUpdateEdgeLength = () => {
  const formFunctions = useFormContext<SolarEnergyProjectValues>();
  const { selectedPatch, selectedRoofIndex, selectedPatchIndex } = useSelectedRoofAndPatch();
  const handleUpdatePatch = useHandleUpdatePatch();
  const selectedPatchIsStraightType =
    formFunctions?.watch(`roofs.${selectedRoofIndex}.patches.${selectedPatchIndex}.shapeType`) === `straight`;

  return (edgeIndex: number, newDistance: number) => {
    const edgeToUpdate = selectedPatch?.edges[edgeIndex];
    if (edgeToUpdate) {
      const startVertexIndex = edgeIndex;
      // move end vertex of the edge to match new distance
      const movedVertexWouldBeFirst = (edgeIndex + 1) % selectedPatch.vertices.length === 0;
      let vertices = getVerticesWithUpdatedLength({
        initialVertices: selectedPatch.vertices,
        newLength: newDistance,
        startVertexIndex,
        reversedDirection: movedVertexWouldBeFirst,
      });
      if (selectedPatchIsStraightType) {
        // move the start vertex on the opposite edge to match straight shape
        vertices = getVerticesWithUpdatedLength({
          initialVertices: vertices,
          newLength: newDistance,
          startVertexIndex: (startVertexIndex + 2) % vertices.length,
          reversedDirection: true,
        });
      }
      handleUpdatePatch({
        ...selectedPatch,
        vertices,
      });
    }
  };
};
