import { Control, useWatch, useFormContext } from 'react-hook-form';
import { SteelOrAluminiumThickness } from './SteelOrAluminiumThickness';
import { AttachementInputs } from './AttachementInputs';
import { TileDimensionsInputs } from './TileDimensionsInputs';
import { useEffect, useMemo } from 'react';
import { SolarPanelAttachmentChips } from './SolarPanelAttachmentChips';
import { RoofMaterialGroupsNames } from '../utils/constants';
import { Stack } from '@mui/material';
import { useGetSolarEnergyProjectSettings } from '@services/api/solarEnergyProjects/solarEnergyProjectsSettings';
import { getValuesFromAttributeRow } from '@pages/NewLeads/project/solarEnergyProject/utils/utils';
import { SolarEnergyProjectValues } from '@pages/NewLeads/project/solarEnergyProject/solarEnergyProjectZodSchema';

interface AdditionalRoofInputsProps {
  control: Control<SolarEnergyProjectValues>;
  roofIndex: number;
}

export const AdditionalRoofInputs = ({ control, roofIndex }: AdditionalRoofInputsProps) => {
  const { setValue } = useFormContext<SolarEnergyProjectValues>();
  const { roofMaterial, roofMaterialGroups } = useGetSolarEnergyProjectSettings();
  const roofMaterialId = useWatch({ control, name: `roofs.${roofIndex}.roofMaterialId` });
  const selectedRoofMaterial = getValuesFromAttributeRow(roofMaterial).find(({ id }) => id === roofMaterialId);
  const materialGroup = useMemo(
    () =>
      getValuesFromAttributeRow(roofMaterialGroups).find(({ id }) => selectedRoofMaterial?.roofMaterialGroupId === id),
    [roofMaterialGroups, selectedRoofMaterial],
  );

  const isTileRoofMaterialGroup = materialGroup?.name === RoofMaterialGroupsNames.tile;
  const isSteelOrAluminiumGroup =
    materialGroup?.name === RoofMaterialGroupsNames.steel || RoofMaterialGroupsNames.aluminium;
  const isGroundGroup = materialGroup?.name === RoofMaterialGroupsNames.ground;

  useEffect(() => {
    if (isGroundGroup) {
      setValue(`roofs.${roofIndex}.solarPanelAttachmentId`, null);
    }
  }, [isGroundGroup, setValue, roofIndex]);

  if (!selectedRoofMaterial) return null;

  return (
    <Stack spacing={3}>
      {isTileRoofMaterialGroup && (
        <TileDimensionsInputs
          roofIndex={roofIndex}
          defaultValues={[selectedRoofMaterial?.defaultTileWidth ?? 0, selectedRoofMaterial?.defaultTileHeight ?? 0]}
          maxTileWidth={selectedRoofMaterial?.maxTileWidth}
          maxTileHeight={selectedRoofMaterial?.maxTileHeight}
        />
      )}
      {isSteelOrAluminiumGroup && (
        <SteelOrAluminiumThickness roofIndex={roofIndex} roofMaterial={selectedRoofMaterial} />
      )}
      {!isGroundGroup && (
        <SolarPanelAttachmentChips
          roofIndex={roofIndex}
          availableAttachmentIds={selectedRoofMaterial.availableAttachmentIds}
        />
      )}
      <AttachementInputs
        roofIndex={roofIndex}
        roofMaterial={selectedRoofMaterial}
        isTileRoofMaterial={isTileRoofMaterialGroup}
        distancesBetweenTopsTinOrMetal={selectedRoofMaterial.maxDistanceBetweenTopsTinOrMetal ?? []}
        minDistanceBetweenFolds={selectedRoofMaterial.minDistanceBetweenFolds}
        maxDistanceBetweenFolds={selectedRoofMaterial.maxDistanceBetweenFolds}
      />
    </Stack>
  );
};
