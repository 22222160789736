import React, { useCallback } from 'react';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { MdiIconWrapper } from '@components/MdiIconWrapper';
import { mdiChevronLeft } from '@mdi/js';
import Box from '@mui/material/Box';
import { OfferPageManagementDropdown } from '@components/OfferManagementDropdown';
import { EditOfferAgreementProps } from '@services/api/offerAgreements/offerAgreements';

export type OfferNavbarProps = {
  onOfferStatusChange: (edit: EditOfferAgreementProps) => Promise<void>;
  isUpdatingOfferStatus: boolean;
  leadId: number;
};
export const OfferNavbar: React.FC<OfferNavbarProps> = ({ onOfferStatusChange, isUpdatingOfferStatus, leadId }) => {
  const {
    translate,
    translations: {
      leads: {
        offer: { headerBackButton },
      },
    },
  } = useTranslations();

  const navigate = useNavigate();

  const handleBack = useCallback(() => {
    navigate(`/leads/${leadId}`);
  }, [leadId, navigate]);

  return (
    <div className="box row fw space-between no-print offer-topbar">
      <Button variant="text" onClick={handleBack} startIcon={<MdiIconWrapper path={mdiChevronLeft} />}>
        {translate(headerBackButton)}
      </Button>
      <Box sx={{ flex: `1 1 auto` }} />
      <OfferPageManagementDropdown onAccept={onOfferStatusChange} isUpdating={isUpdatingOfferStatus} />
    </div>
  );
};
