import '@components/navbar/navbar.css';
import { showNext, VIEW_TYPES } from '@components/columnLayout/utils';
import React, { useEffect, useMemo } from 'react';
import { OfferAgreementDto, LeadOfferStatus, TeaserContentDto } from '@generatedTypes/data-contracts';
import { EditOfferAgreementProps } from '@services/api/offerAgreements/offerAgreements';
import { OfferCardPartnerLeadInformation } from './components/OfferCardPartnerLeadInformation';
import { GridLayoutWithSideElementAndMargins } from '@components/gridLayout/gridLayoutWithSideElementAndMargins';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from '@redux/selectors/user';
import { Stack } from '@mui/material';
import { HorizontalLine } from '@components/dividers/horizontal-line';
import { ProjectsCards } from '@pages/offer/components/projects/Projects';
import { OfferAttachments } from '@pages/offer/components/OfferAttachments';
import { Teasers } from '@pages/offer/components/Teasers';
import { TermsAndConditionsAndContact } from '@pages/offer/components/AcceptOffer/TermsAndConditions';
import { OfferHeader } from '@pages/offer/components/OfferHeader';
import { OfferNavbar } from '@pages/offer/components/OfferNavbar';
import { OfferCardIntroInformation } from '@pages/offer/components/OfferIntro';
import { AcceptOfferCard } from '@pages/offer/components/AcceptOffer/AcceptOfferCard';
import './offer.css';

type OfferDetailsPageProps = {
  offerDetails: OfferAgreementDto;
  sendOfferAgreement: (payload: EditOfferAgreementProps) => Promise<void>;
  isSendingOfferAgreement: boolean;
  teasers: TeaserContentDto[];
};

export const OfferDetailsPage: React.FC<OfferDetailsPageProps> = ({
  offerDetails,
  sendOfferAgreement,
  teasers,
  isSendingOfferAgreement,
}) => {
  const isLoggedInUser = useSelector(selectIsLoggedIn);
  const isPartnerAssigned = useMemo(() => {
    return !!offerDetails.partner?.name;
  }, [offerDetails.partner?.name]);

  useEffect(() => {
    showNext(VIEW_TYPES.INFO)();
  }, []);

  return (
    <Stack>
      {isLoggedInUser && !!offerDetails.leadId && (
        <OfferNavbar
          onOfferStatusChange={sendOfferAgreement}
          isUpdatingOfferStatus={isSendingOfferAgreement}
          leadId={offerDetails.leadId}
        />
      )}
      <Stack
        marginTop={7.5}
        className={`offer-container ${!isPartnerAssigned ? `offer-page--no-partner-assigned` : ``}`}
      >
        <GridLayoutWithSideElementAndMargins sideSticky>
          <Stack spacing={4}>
            <OfferHeader leadOfferStatus={offerDetails.status ?? LeadOfferStatus.Created} />
            <Stack spacing={4}>
              {isPartnerAssigned && <OfferCardPartnerLeadInformation offerDetails={offerDetails} />}
              <OfferCardIntroInformation offerDetails={offerDetails} />
            </Stack>
            <HorizontalLine classes="gray" />
            <ProjectsCards
              carChargingBids={offerDetails.details?.carChargingBids ?? []}
              solarEnergyBids={offerDetails.details?.solarEnergyBids ?? []}
              emptyBids={offerDetails.details?.emptyBids ?? []}
              energyStorageBids={offerDetails.details?.energyStorageBids ?? []}
              isPartnerAssigned={isPartnerAssigned}
              bidPrices={offerDetails.details?.price?.bidPrices ?? []}
            />
            <HorizontalLine classes="gray" />
            <OfferAttachments attachments={offerDetails.details?.attachments ?? []} />
          </Stack>
          <AcceptOfferCard offerDetails={offerDetails} loggedInUser={isLoggedInUser} />
        </GridLayoutWithSideElementAndMargins>
        <Stack>
          {isPartnerAssigned ? <TermsAndConditionsAndContact offerDetails={offerDetails} /> : null}
          <Teasers teasers={teasers} />
        </Stack>
      </Stack>
    </Stack>
  );
};
