import { useFormContext } from 'react-hook-form';
import { useContext } from 'react';
import { SolarMapVisualisationContext, SolarMapVisualisationDispatchContext } from '../context';
import { getRoofIdFromPatch, useSelectedRoofAndPatch } from '../../accessors';
import { togglePanel } from '../../panel';
import { getDispatchSolarEnergyProject } from '../store';
import { SolarEnergyProjectValues } from '@pages/NewLeads/project/solarEnergyProject/solarEnergyProjectZodSchema';

export const useHandlePanelClick = () => {
  const formFunctions = useFormContext<SolarEnergyProjectValues>();
  const { solarEnergyProject } = useContext(SolarMapVisualisationContext);
  const dispatch = useContext(SolarMapVisualisationDispatchContext);
  const { selectedRoof } = useSelectedRoofAndPatch();
  const dispatchSolarEnergyProject = getDispatchSolarEnergyProject(dispatch);

  return (panelId: number, patchId: string) => () => {
    const roofId = getRoofIdFromPatch(patchId);
    if (roofId === selectedRoof?.id) {
      const newProject = togglePanel({ solarEnergyProject, patchId, panelId, setValue: formFunctions?.setValue });
      if (newProject) {
        dispatchSolarEnergyProject(newProject);
      }
    }
  };
};
