import { ColumnItem } from '@components/columnLayout/utils';
import { FormSection } from '@components/forms/MuiFormSection';
import { FormWrapper } from '@components/forms/MuiFormWrapper';
import { useTranslations } from '@services/hooks/translations/useTranslations';
import {
  useDeleteOfferAttachment,
  useUpdateOfferAttachment,
  useUploadOfferAttachment,
} from '@services/api/offerAttachments/offerAttachments';
import { useSelector } from 'react-redux';
import { selectSelectedOfferAttachment } from '@redux/reducers/slices/offerTab';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { InputWrapper } from '@components/controls/react-hook-form-friendly/smart';
import { useGetOffer } from '@services/api/offers/offers';
import { DevTool } from '@hookform/devtools';
import { FileInput } from '@components/controls/react-hook-form-friendly/smart/FileInput';
import { mdiTrashCanOutline } from '@mdi/js';

const MAX_UPLOAD_SIZE = 1024 * 1024 * 25; // 3MB
const ACCEPTED_FILE_TYPES = [
  `application/vnd.openxmlformats-officedocument.wordprocessingml.document`,
  `application/pdf`,
];

type UploadOfferAttachmentProps = {
  goBackHandler: () => void;
};

export const UploadOfferAttachment: ColumnItem<UploadOfferAttachmentProps> = ({ goBackHandler }) => {
  const { offer } = useGetOffer();
  const selectedOfferAttachment = useSelector(selectSelectedOfferAttachment);
  const { mutate: uploadOfferAttachment } = useUploadOfferAttachment({ onSuccess: goBackHandler });
  const { mutate: updateOfferAttachment } = useUpdateOfferAttachment({ onSuccess: goBackHandler });
  const { mutate: deleteOfferAttachment } = useDeleteOfferAttachment({ onSuccess: goBackHandler });

  const fileName =
    selectedOfferAttachment?.name && selectedOfferAttachment?.extension
      ? `${selectedOfferAttachment.name}${selectedOfferAttachment.extension}`
      : null;

  const {
    translate,
    translations: {
      validationErrors: { NOT_NULL },
      leads: {
        offer: { attachmentsForm },
      },
    },
  } = useTranslations();

  const offerAttachmentZodObject = z.object({
    name: z.string(),
    file: z
      .instanceof(File)
      .optional()
      .refine((file) => !file || file.size <= MAX_UPLOAD_SIZE, translate(attachmentsForm.fileTooBigError))
      .refine(
        (file) => ACCEPTED_FILE_TYPES.includes(file?.type ?? ``),
        translate(attachmentsForm.fileTypeNotAllowedError),
      )
      .nullable()
      .refine((file) => !!file, translate(NOT_NULL)),
  });

  type OfferAttachment = z.infer<typeof offerAttachmentZodObject>;

  const { control, handleSubmit } = useForm({
    defaultValues: {
      name: selectedOfferAttachment?.name ?? ``,
      file: fileName ? new File([], fileName) : null,
    },
    resolver: zodResolver(offerAttachmentZodObject),
  });

  const onDelete = selectedOfferAttachment?.id
    ? () => {
        if (selectedOfferAttachment?.id) {
          deleteOfferAttachment(selectedOfferAttachment.id);
        }
      }
    : undefined;

  function onSubmit({ name, file }: OfferAttachment) {
    if (!file) {
      return;
    }
    if (offer) {
      if (selectedOfferAttachment?.id) {
        updateOfferAttachment({ id: selectedOfferAttachment.id, file, name, offerId: offer.id });
      } else {
        uploadOfferAttachment({ offerId: offer.id, file, name });
      }
    }
  }

  return (
    <FormWrapper
      onCancel={goBackHandler}
      onSubmit={handleSubmit(onSubmit)}
      titleAction={onDelete}
      iconPath={mdiTrashCanOutline}
      title={selectedOfferAttachment?.name ?? translate(attachmentsForm.header)}
      titleVariant="h2"
    >
      <FormSection spacing={4}>
        <InputWrapper isRequired control={control} name="name" label={translate(attachmentsForm.fileName)} />
        <FileInput
          required
          control={control}
          name="file"
          buttonText={translate(attachmentsForm.uploadFileButton)}
          label={translate(attachmentsForm.uploadFileLabel)}
          description={translate(attachmentsForm.description)}
        />
      </FormSection>
      <DevTool control={control} />
    </FormWrapper>
  );
};
