import { Control, Path } from 'react-hook-form';
import { ChipImageList } from '@components/controls/react-hook-form-friendly/smart/ChipImageList';
import Stack from '@mui/material/Stack';
import { useGetSolarEnergyProjectSettings } from '@services/api/solarEnergyProjects/solarEnergyProjectsSettings';
import { getValuesFromAttributeRow } from '@pages/NewLeads/project/solarEnergyProject/utils/utils';
import { SolarEnergyProjectValues } from '@pages/NewLeads/project/solarEnergyProject/solarEnergyProjectZodSchema';

interface RoofTypeProps {
  control: Control<SolarEnergyProjectValues>;
  name: Path<SolarEnergyProjectValues>;
  label: string;
}

export const RoofType = ({ control, name, label }: RoofTypeProps) => {
  const { roofType } = useGetSolarEnergyProjectSettings();
  const roofTypes = getValuesFromAttributeRow(roofType).map(({ id, name, imageUri }) => ({
    title: name as string,
    src: imageUri as string,
    value: id,
  }));

  return (
    <Stack spacing={2}>
      <ChipImageList
        title={label}
        control={control}
        name={name}
        chips={roofTypes}
        wrap="nowrap"
        defaultValue={roofTypes[0].value}
      />
    </Stack>
  );
};
